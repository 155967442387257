import React from 'react';
import FilterIcon from 'client/img/icons/mobile-filter.svg';
import BackIcon from 'client/img/icons/back.svg';
import { t } from 'i18next';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import './style.scss';
import { Button } from '@smartplatform/ui';
import PropTypes from 'prop-types';

@observer
export default class MobileFilter extends React.Component {
	static propTypes = {
		applyFilter: PropTypes.func,
		resetFilter: PropTypes.func,
	};

	@observable showFilters = false;
	@observable isApply = false;

	constructor(props) {
		super(props);
	}

	toggleShowFilters = () => {
		this.showFilters = !this.showFilters;
	};

	applyFilter = () => {
		if (this.props.applyFilter) this.props.applyFilter();
		this.showFilters = false;
		this.isApply = true;
	};

	resetFilter = () => {
		if (this.props.resetFilter) this.props.resetFilter();
		this.showFilters = false;
		this.isApply = false;
	};

	render() {
		const { showFilters, toggleShowFilters, props, resetFilter } = this;

		return (
			<div className='wrapper-mobile-filter'>
				<div className='wrapper-btn-mobile-filter' onClick={toggleShowFilters}>
					<FilterIcon />
					<p className='title'>{t('filter.plural')}</p>
				</div>
				<div
					className={classNames({
						'wrapper-mobile-filter-content': true,
						active: showFilters,
					})}
				>
					<div className='controls-mobile-filter'>
						<div className='top-filter' onClick={toggleShowFilters}>
							<BackIcon />
							<p className='title'>{t('filter.plural')}</p>
						</div>
						<div className='reset-filter' onClick={resetFilter}>
							<p className='reset'>{t('filter.reset')}</p>
						</div>
					</div>
					<div className='bottom-filter'>
						<div className='fields'>{props.children}</div>
						<Button onClick={this.applyFilter} variant='primary'>
							{t('filter.apply')}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
