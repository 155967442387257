import { t } from 'i18next';

export const HOURS = [
	'00:00',
	'01:00',
	'02:00',
	'03:00',
	'04:00',
	'05:00',
	'06:00',
	'07:00',
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
	'22:00',
	'23:00',
];

export const DAYS_NAME = [
	t('calendarRecords.weekDays.mon'),
	t('calendarRecords.weekDays.tue'),
	t('calendarRecords.weekDays.wed'),
	t('calendarRecords.weekDays.thu'),
	t('calendarRecords.weekDays.fri'),
	t('calendarRecords.weekDays.sat'),
	t('calendarRecords.weekDays.sun'),
];
