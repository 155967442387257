import React from 'react';
import Root from './Root';
import t from 'i18n';
import { faFile, faGear } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENTS_PATH, DOCUMENT_SETTINGS_PATH } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('document.moduleTitle'),

	/** Короткий заголовок модуля */
	titleShort: t('document.plural'),

	/** Код модуля на латинице, обязателен */
	code: 'documents',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Document?.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда */
	// dashboardCard: DocumentsCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			path: DOCUMENTS_PATH,
			label: t('document.plural'),
			icon: faFile,
			enabled: (store) => store.model.Document?.INFO.READ,
		},
		{
			type: 'item',
			label: t('settings'),
			path: DOCUMENT_SETTINGS_PATH,
			reactIcon: <FontAwesomeIcon icon={faGear} />,
			enabled: (store) => store.model.DocumentType?.INFO.WRITE && store.model.DocumentStatus?.INFO.WRITE,
		},
	],
};
