import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { declinationOfNumber } from 'client/tools';
import AddIcon from '../../img/add-recoord-calendar.svg';
import store from 'client/store';
import CardNew from 'components/calendar-records/CardNew';
import classNames from 'classnames';
import CardRecord from './CardRecord';
import { t } from 'i18next';
import { getLabelParentByModel } from 'client/tools/getLabelParentByModel';

@inject('store')
@observer
export default class CellCalendar extends React.Component {
	@observable NewRecord = null;
	day = null;
	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	addNewRecord = () => {
		const { model, dateProperty } = this.props;
		this.NewRecord = new model();
		this.NewRecord[dateProperty] = this.props.day.fullDate;
	};

	onSave = async () => {
		if (!this.props.beforeSave) {
			await this.NewRecord.save();
		} else {
			const record = this.props.beforeSave(this.NewRecord);
			await record.save();
			this.NewRecord.id = record.id;
		}
		if (this.props.addItem) await this.props.addItem(this.NewRecord);
		this.NewRecord = null;
	};

	onCancel = () => {
		this.NewRecord = null;
	};

	render() {
		const { renderItem, titleProperty, filteredRecords, enableAdd, model, mode, day } = this.props;
		const modelTranslation = getLabelParentByModel(model.INFO.name);
		return (
			<>
				<div className='wrapper-top-content'>
					{enableAdd && (
						<button className={classNames({ 'add-record-btn': true, disable: !!this.NewRecord })} onClick={this.addNewRecord}>
							<AddIcon />
						</button>
					)}
					{mode === 'month' && (
						<div className='right'>
							<p className='count'>{`${filteredRecords.length} ${t(`${modelTranslation}.${modelTranslation}`, { count: filteredRecords.length })}`}</p>
							<p className='head-day-date'>{day.numberDay}</p>
						</div>
					)}
				</div>
				{this.NewRecord && (
					<CardNew record={this.NewRecord} titleProperty={titleProperty} onSave={this.onSave} onCancel={this.onCancel} />
				)}
				{filteredRecords.map((record, index) => (
					<CardRecord key={index} record={record} renderItem={renderItem} />
				))}
			</>
		);
	}
}
