import React from 'react';
import t from 'i18n';
import './userSettings.scss';
import { DATASOURCES, NOTIFICATIONS, NotificationSettingsStore } from './notificationSettingsStore';
import { observer } from 'mobx-react';
import { Button, Switch } from 'components';
import PropTypes from 'prop-types';
import store from 'client/store';
import { TwoFactor } from 'components/two-factor';

@observer
export class UserSettings extends React.Component {
	static propTypes = {
		userId: PropTypes.number,
		defaultValue: PropTypes.bool,
	};

	static defaultProps = {
		defaultValue: false,
	};

	constructor(props) {
		super(props);
		this.notificationSettingsStore = new NotificationSettingsStore({ userId: props.userId, defaultValue: props.defaultValue });
	}

	render() {
		const { isLoading, onSave, isChanged, notificationSettings, onChange } = this.notificationSettingsStore;
		if (isLoading) return null;
		return (
			<div className='user-settings'>
				<div className='notification-settings'>
					{Object.keys(NOTIFICATIONS).map((model) => {
						const modelLowerName = model.toLowerCase();
						return (
							<>
								<h2 className='model-name'>{t(modelLowerName + '.plural')}</h2>
								<table className='sp-table'>
									<thead>
										<th>{t('name')}</th>
										{DATASOURCES.map((dataSource) => (
											<th>{t(`${dataSource}.title`)}</th>
										))}
									</thead>
									<tbody>
										{NOTIFICATIONS[model].map((code) => (
											<tr className='notification'>
												<td>{t(`notification.${modelLowerName}.${code}`)}</td>
												{DATASOURCES.map((dataSource) => {
													const key = `notification.${model}.${code}.${dataSource}`;
													return (
														<td>
															<Switch
																onChange={(value) => onChange(key, value)}
																value={notificationSettings.get(key)}
															/>
														</td>
													);
												})}
											</tr>
										))}
									</tbody>
								</table>
							</>
						);
					})}
					<div className='actions'>
						<Button disabled={!isChanged} onClick={onSave}>
							{t('notification.save')}
						</Button>
					</div>
				</div>
				{this.props.userId === store.model.user.id && <TwoFactor userId={this.props.userId} />}
			</div>
		);
	}
}
