import React from 'react';
import { inject, observer } from 'mobx-react';
import store from 'client/store';
import CalendarWithInitialState from 'components/calendar-records/CalendarWithInitialState';
import OrderCard from 'client/modules/sales/orders/kanban/OrderCard';
import { ORDER_KANBAN_INCLUDE } from 'client/modules/sales/orders/constants';
import { ORDERS_PATH } from 'client/modules/sales/constants';

@inject('store')
@observer
export default class OrdersCalendar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	beforeSave = (record) => new store.model.Order(record);

	renderOrder = (record) => (
		<div
			className='calendar-order-card'
			style={{ borderLeftColor: record?.list?.color }}
			onClick={() => store.route.push({ path: `${ORDERS_PATH}/${record.id}` })}
		>
			<OrderCard order={record} store={this.store} />
		</div>
	);

	render() {
		const { filters, localStorage } = this.store;

		return (
			<CalendarWithInitialState
				localStorage={localStorage}
				renderItem={this.renderOrder}
				dateProperty='endDate'
				model={store.model.ViewOrder}
				titleProperty='description'
				beforeSave={this.beforeSave}
				filter={{ where: filters, include: [{ relation: 'list', scope: { fields: ['color'] } }, ...ORDER_KANBAN_INCLUDE] }}
			/>
		);
	}
}
