import React from 'react';
import { Form as Form_ } from '@smartplatform/ui';
import { Actionbar } from './actionbar';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { triggerErrorNotification, triggerSaveNotification, triggerDeleteNotification } from 'components';
import './formComponent.scss';
import { observer } from 'mobx-react';
import t from 'i18n';
import { lowerFirstLetter } from '../../tools/stringHelpers';

@observer
export class Form extends React.Component {
	static propTypes = {
		noSave: PropTypes.bool,
		noDelete: PropTypes.bool,
		noCancel: PropTypes.bool,
		saveTitle: PropTypes.string,
		deleteTitle: PropTypes.string,
		cancelTitle: PropTypes.string,
		onCancel: PropTypes.func,
		disableSave: PropTypes.bool,
		disableDelete: PropTypes.bool,
		className: PropTypes.string,
		checkIsDirty: PropTypes.bool,
	};

	static defaultProps = {
		showNotifications: true,
		checkRequiredFields: true,
		showControls: true,
		checkIsDirty: false,
	};

	constructor(props) {
		super(props);
		this.formElementRef = React.createRef();
	}

	onError = (error) => {
		let message = error.message;
		if (error.name === 'ValidationError') {
			const model = lowerFirstLetter(this.props.record.MODEL.INFO.name);
			message = Object.entries(error.details.messages)
				.map(([code, message]) => `${t(`${model}.${code}`)}: ${message}`)
				.join(', ');
		}
		this.props.onError?.(error);
		this.props.showNotifications && message && triggerErrorNotification(message);
	};

	onSave = (record) => {
		this.props.onSave?.(record);
		this.props.showNotifications && triggerSaveNotification();
	};

	onDelete = (record) => {
		this.props.onDelete?.(record);
		this.props.showNotifications && triggerDeleteNotification();
	};

	get isRequiredFieldsFilled() {
		const { PROPERTIES } = this.props.record.MODEL;
		const requiredFields = Object.keys(PROPERTIES).filter((key) => PROPERTIES[key].required === true);
		return !requiredFields.find((key) => !this.props.record[key] && this.props.record[key] !== 0);
	}

	render() {
		const { onError, onSave, onDelete } = this;

		let {
			children,
			noSave,
			noDelete,
			noClean,
			noCancel,
			saveTitle,
			deleteTitle,
			cancelTitle,
			cleanTitle,
			onCancel,
			onClean,
			disableSave,
			disableDelete,
			className,
			controls,
			showControls,
			isNew,
			checkRequiredFields,
			checkIsDirty,
			...formProps
		} = this.props;

		if (checkRequiredFields) {
			disableSave = disableSave || !this.isRequiredFieldsFilled;
		}

		if (checkIsDirty) {
			disableSave = disableSave || !this.props.record.isDirty;
		}

		const className_ = classNames(className, { 'no-form-submit': noCancel && noDelete && noSave });

		const controlsProps = {
			isNew,
			noSave,
			noDelete,
			noCancel,
			noClean,
			saveTitle,
			deleteTitle,
			cancelTitle,
			cleanTitle,
			onCancel,
			onClean,
			disableSave,
			disableDelete,
			controls,
			formElementRef: this.formElementRef,
		};

		return (
			<Form_
				noDelete
				noSave
				stay
				className={className_}
				{...formProps}
				controls={showControls && <Actionbar {...controlsProps} />}
				onError={onError}
				onSave={onSave}
				onDelete={onDelete}
			>
				<div ref={this.formElementRef}>{children}</div>
			</Form_>
		);
	}
}

