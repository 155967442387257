import React from 'react';
import store from 'client/store';
import CalendarRecords from 'components/calendar-records/CalendarRecords';

export default class CalendarWithInitialState extends React.Component {
	initialState = {};
	constructor(props) {
		super(props);
		this.localStorage = this.props.localStorage;
		if (this.localStorage) {
			this.loadInitialState();
		}
	}

	loadInitialState = () => {
		if (this.localStorage?.calendar) {
			const storageKeys = Object.keys(this.localStorage?.calendar);
			if (storageKeys.length)
				storageKeys.forEach((key) => {
					this.initialState[key] =
						key === 'currentDate' ? new Date(this.localStorage?.calendar[key]) : this.localStorage?.calendar[key];
				});
		}
	};

	onLocalStorageSave = (prop, value) => {
		if (!!prop && !!value && this.localStorage) {
			if (!this.localStorage?.calendar) this.localStorage.calendar = {};
			this.localStorage.calendar[prop] = value;
			store.local.save();
		}
	};

	render() {
		return <CalendarRecords {...this.props} initialState={this.initialState} onLocalStorageSave={this.onLocalStorageSave} />;
	}
}
