import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import CellCalendar from 'components/calendar-records/CellCalendar';
import { AutoAnimate } from '@smartplatform/ui';
import { formatDate } from 'client/tools';
import { t } from 'i18next';
import { getLabelParentByModel } from 'client/tools/getLabelParentByModel';

@inject('store')
@observer
export default class MonthMode extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	render() {
		const { daysCalendar, handleDrop, handleDragOver } = this.store;
		const { dateProperty, records, model } = this.props;
		const modelTranslation = getLabelParentByModel(model.INFO.name);

		return (
			<div className='month-calendar'>
				{Object.keys(daysCalendar).map((key) => (
					<div key={key} className='day-week'>
						<p className='head-day-week'>{daysCalendar[key].name}</p>
						<div className='wrapper-day-cards'>
							{daysCalendar[key].days.map((day, index) => {
								const filteredRecords = records.filter(
									(record) => formatDate(record[dateProperty], 'dd.MM.yyyy') === formatDate(day.fullDate, 'dd.MM.yyyy')
								);
								return (
									<AutoAnimate
										key={index}
										className={classNames({ 'day-month': true, active: day.active })}
										onDrop={(e) => handleDrop(e)}
										onDragOver={(e) => handleDragOver(e, day.fullDate)}
									>
										<CellCalendar day={day} filteredRecords={filteredRecords} {...this.props} />
									</AutoAnimate>
								);
							})}
						</div>
					</div>
				))}
			</div>
		);
	}
}
