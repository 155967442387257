import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '@smartplatform/ui';
import {
	triggerDeleteNotification,
	triggerErrorNotification,
	triggerLogInNotification,
	triggerSaveNotification,
	triggerTaskNotification,
	triggerWarningNotification,
} from '../../notifications/triggers';
import store from '../../../store';
import './style.scss';

@observer
export default class Notifications extends React.Component {
	online = async () => {
		const users = await store.model.User.find({ avatar: { neq: null }, limit: 50 });
		const user = users[Math.floor(Math.random() * users.length)];
		triggerLogInNotification(user);
	};
	task = async () => {
		const tasks = await store.model.Task.find({ limit: 30 });
		const task = tasks[Math.floor(Math.random() * tasks.length)];
		triggerTaskNotification(task);
	};
	render() {
		return (
			<div className='notification-test'>
				<div>
					{' '}
					<Button variant='info' onClick={this.online}>
						online
					</Button>
					<Button variant='info' onClick={this.task}>
						task
					</Button>
				</div>
				<div>
					<Button variant='primary' onClick={() => triggerSaveNotification()}>
						save
					</Button>
					<Button onClick={() => triggerDeleteNotification()}>delete</Button>
					<Button variant='warning' onClick={() => triggerWarningNotification('Предупреждение')}>
						warning
					</Button>
					<Button variant='danger' onClick={() => triggerErrorNotification('Всё сломалось')}>
						error
					</Button>
				</div>
			</div>
		);
	}
}

