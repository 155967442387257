export default {
	translation: {
		quantity: 'Quantity',
		changeDescription: 'Change Description',
		noStatus: 'No Status',
		watchAll: 'Watch All',
		performanceIndicator: 'KPI',
		change: 'Change',
		time: 'Time',
		mine: 'My',
		show: 'Show',
		nobody: 'Nobody',
		recordNotFound: 'Record with this ID was not found',
		reset: 'Reset',
		typeText: 'Enter Text',
		recover: 'Recover',
		code: 'Code',
		pickDate: 'Select Date',
		appSettings: 'Application Settings',
		online: 'Online',
		consumption: 'Usage',
		open: 'Open',
		any: 'Any',
		deleteDescription: 'Are you sure you want to delete the record? It will be impossible to cancel this action ',
		article: 'Item Number',
		selectAll: 'Select All',
		clearAll: 'Clear All',
		overdueNotClosed: 'Overdue (open)',
		overdueNotClosedPopover: 'Number of overdue tasks in progress',
		completedTasksPopover: 'Total number of completed tasks in selected period',
		overdueClosed: 'Overdue (closed)',
		overdueClosedPopover: 'The number of tasks completed with overdue in selected period ',
		notOverdue: 'Not overdue',
		mainInfo: 'Main information',
		dueDateNow: 'Expires today',
		dueDateNowPopover: 'Number of tasks that expire today ',
		perfomanceAllTaskPopover: 'Number of tasks created in selected period ',
		state: 'State',
		responsible: 'Responsible ',
		selectLists: 'Assign States',
		searchName: 'Search by name',
		searchNameAndNumber: 'Search by name and number ',
		searchNumber: 'Search by number ',
		confirm: 'Confirm',
		selectTheme: 'Select Theme',
		address: 'Address',
		noSelectedLists: `You don't have a task status list`,
		selected: 'Selected',
		pickFromList: 'Select from list',
		chart: 'Chart',
		select: 'Select from list',
		export: 'Export',
		author: 'Author',
		import: 'Import',
		exportCSV: 'CSV Export',
		importCSV: 'CSV Import',
		exportTXT: 'TXT Export',
		importTXT: 'TXT Import',
		exportXLSX: 'XLSX Export',
		clean: 'Clean',
		actions: 'Actions',
		noProjects: 'No projects specified',
		all: 'All',
		day: 'Day',
		week: 'Week',
		currentWeek: 'Current Week',
		weeks: 'Weeks',
		monthes: 'Months',
		complited: 'Completed',
		uncomplited: 'Uncompleted',
		currentMonth: 'Current Month',
		month: 'Month',
		year: 'Year',
		quarter: 'Quarter',
		byQuarter: 'By Quarter',
		halfyear: 'Half a Year',
		from: 'From',
		to: 'To',
		periodFrom: 'Period From',
		periodTo: 'Period To',
		defaultTitle: 'SmartERP',
		name: 'Name',
		number: 'Number',
		fio: 'Name',
		members: 'Members',
		unnamed: 'Unnamed',
		type: 'Type',
		firstName: 'First name',
		lastName: 'Last name',
		middleName: 'Middle name',
		amount: 'Quantity',
		fullName: 'Full name',
		doubleClickToEdit: 'Double Click to Edit',
		description: 'Description',
		fact: 'Fact',
		addDescription: 'Add Description',
		add: 'Add',
		finance: 'Finance',
		loading: 'Loading...',
		information: 'Information',
		toAttachFile: 'Attach File',
		attachments: 'Attached Files',
		files: 'Files',
		uploadFiles: 'Upload Files',
		deleteFile: 'Delete File',
		deleteCategory: 'Delete Category?',
		uploadingFiles: 'Uploading Files',
		uploadError: 'Upload Error',
		uploadingFile: 'Uploading File',
		uploadSuccess: 'Upload Success',
		rejectedFiles: 'Rejected files',
		selectedCount: 'Selected Count',
		dragDropHint: 'Drag and drop files here or click to select files',
		editHint: 'Double click to edit',
		editHintMobile: 'Long tap to edit',
		management: 'Management',
		sum: 'Sum',
		tax: 'Tax',
		profit: 'Profit',
		income: 'Income',
		costs: 'Costs',
		startRemains: 'The balance at the Beginning',
		endRemains: 'The balance at the End',
		date: 'Date',
		rub: '?',
		administration: 'Administration',
		admin: 'Admin',
		usersAndRoles: 'Users and Roles',
		settings: 'Settings',
		percent: 'Percent',
		percentChar: '%',
		price: 'Price',
		paid: 'Paid',
		phone: 'Phone Number',
		create: 'Create',
		search: 'Search',
		more: 'More',
		moreEllipsis: 'More...',
		next: 'Next',
		nextEllipsis: 'Next...',
		notFound: 'Not Found',
		nothingFound: 'Nothing was found',
		edit: 'Edit',
		endEdit: 'End edit',
		save: 'Save',
		saving: 'Saving...',
		saved: 'Saved',
		savedAt: 'Saved at',
		cancel: 'Cancel',
		delete: 'Delete',
		clear: 'Clear',
		confirmDelete: 'Please confirm deleting',
		deleting: 'Deleting...',
		deleted: 'Deleted',
		updatedAt: 'Updated',
		error: 'Error',
		ok: 'OK',
		yes: 'Yes',
		no: 'No',
		projects: 'Projects',
		board: 'Board',
		agent: 'Agent',
		clients: 'Clients',
		persons: 'Persons',
		noSelected: 'Not selected',
		noCreate: 'Object creation is not allowed',
		payments: 'Payments',
		paymentInfo: 'Payment details',
		realm: 'Realm',
		status: 'Status',
		color: 'Color',
		image: 'Image',
		avatar: 'Avatar',
		notSet: 'Not specified',
		priority: 'Priority',
		created: 'Created',
		gb: 'GB',
		confirmPopup: {
			message: 'Entered data will be deleted. Confirm?',
			postComment: 'Entered comment will be deleted. Confirm?',
			comment: 'Changes in the comment will be deleted. Confirm?',
		},
		telegram: {
			title: 'Telegram',
			token: 'Bot token',
			bot: 'Bot name',
			proxy: 'Proxy',
			testBot: 'Bot test',
		},
		modify: {
			title: 'Modify',
			description: 'Change description',
		},
		modified: 'Modified',
		username: 'User name',
		users: 'Users',
		executor: 'Assigned to',
		roles: 'Roles',
		acls: 'ACLS',
		perPage: 30,
		itemsPerPage: 'Items per page',
		invalidId: 'Invalid ID',
		modelAccessDenied: 'Model Access Denied',
		private: 'Private',
		startDate: 'Start Date',
		dueDate: 'Due Date',
		roleMappings: 'Role assignment',
		'Element does not exist': 'Element does not exist',
		'Element updated': 'Element updated',
		'Element created': 'Element created',
		'Element deleted': 'Element deleted',
		percentLeft: 'Percent left',
		authorization: 'Authorization',
		rewritePassword: 'Password change',
		rewritePasswordHint: 'Password expired, please enter new password',
		login: 'Login',
		logout: 'Logout ',
		LOGIN_FAILED: 'Invalid Username/Password ',
		LOGIN_WAIT: 'Limit of attempts exceeded',
		USER_BLOCKED: 'Account blocked',
		NO_SESSION_CAPTCHA: 'No new code generated ',
		EMPTY_CAPTCHA: 'Code not entered ',
		INVALID_CAPTCHA: 'Invalid Code ',
		AUTHORIZATION_REQUIRED: 'Log in required',
		EMAIL_NOT_FOUND: 'Email not found',
		Unauthorized: 'You need to log in',
		required: 'Field must be filled in',
		datePickerPlaceholder: 'DD.MM.YY',
		dateTimePickerPlaceholder: 'DD.MM.YY HH:MM',
		oldContentPopover: 'This section is no longer supported',
		apply: 'Apply',
		offline: 'Offline',
		postComment: {
			placeholder: 'Enter a comment...',
		},
		module: {
			settings: 'Module Settings',
			sales: {
				title: 'Sales',
				showOldPriceList: 'Show old version of pricelist',
				showOrderWaybill: 'Show invoice in order',
				showOrderPassport: 'Show data in order',
				showOrderOldPositions: 'Show old order items',
				showOrderTechMap: 'Show process chart',
				showLoginNotifications: 'Show login notifications',
			},
			manufacture: {
				title: 'Manufacture',
				defaultTechnologist: 'Default technologist',
				enableCalibrationQuantity: 'Calibration amount of material (fitting)',
			},
			management: {
				title: 'Management',
				showTaskCustomFields: 'Show task custom fields',
				showProjectCustomFields: 'Show project custom fields',
			},
			admin: {
				title: 'Administration',
			},
			documents: {
				title: 'Documents',
			},
			contragents: {
				title: 'Contragents',
			},
			finance: {
				title: 'Finance',
			},
			storehouse: {
				title: 'Storehouse',
			},
			messages: {
				title: 'Messages',
			},
		},
		feedback: {
			title: 'Feedback form',
			new: 'New Appeal',
			phone: 'Phone number',
			email: 'Email',
			subject: 'Subject',
			send: 'Send',
			description: 'Description',
			sent: 'Your appeal has been registered',
			invalidEmail: 'Invalid E-mail',
		},
		states: {
			new: 'New state',
		},
		parameters: {
			new: 'New parameter',
		},
		dashBoard: 'Home',
		datePeriod: {
			select: 'Select period',
			specified: 'Arbitrary period',
			currentMonth: 'Current month',
			currentQuarter: 'Current quarter',
			currentYear: 'Current year',
			lastMonth: 'Last month',
			lastQuarter: 'Last Quarter',
			lastYear: 'Last Year',
			fiveYears: '5 years',
			all: 'All the time',
			year: 'Year',
			month: 'Month',
			quarter: 'Quarter',
			day: 'Day',
			week: 'Week',
			halfDay: '12 hours',
			quarterDay: '6 hours',
			hour: 'Hour',
			comparisonPeriod: 'Comparison Period',
			scale: 'Scale',
		},
		checklist: {
			new: 'New checklist',
			hintEmpty: 'Blank items are deleted when saved',
			hintDrag: 'Items can be swapped',
			plural: 'Checklists',
			add: 'Add a checklist',
			addPosition: 'Add item',
			name: 'Checklist title',
			typeChecklistName: 'Enter checklist title',
			typeChecklistPositionName: 'Enter item',
			delete: 'Delete checklist?',
			title: 'Checklist is a tool that helps to organize and control the completion of tasks',
		},
		auth: {
			TOKEN_ROTTEN: 'Token expired',
			title: 'Authorization',
			login: 'Login to your account',
			logout: 'Log out',
			goBack: 'Back',
			register: 'Register',
			restore: 'Password Recovery',
			next: 'Next',
			email: 'E-mail',
			lastName: 'Last name',
			firstName: 'Name',
			middleName: 'Middle name',
			password: 'Password',
			order: 'Get access',
			enterEmail: 'Enter E-mail',
			enterLogin: 'Enter your username',
			enterPassword: 'Enter password',
			repeatPassword: 'Confirm password',
			enterRepeatedPassword: 'Confirm password',
			repeat: 'Repeat',
			minPasswordLength: 'Minimum password length',
			invalidEmail: 'Invalid email',
			emailExists: 'Such email already registered',
			registrationSuccess: 'Confirmation link sent to your email ($email)',
			noMatch: `Passwords don't match`,
			enterCode: 'Enter code',
			forgotPassword: 'Forgot your password?',
			recover: 'Recover',
			required: 'Field must be filled',
			wait: 'Wait',
			seconds: 'seconds',
			limitExceeded: 'Limit of attempts exceeded',
			tryAgain: 'Enter your username /password',
			registration: 'Registration',
			captcha: 'Code from picture',
			simplePassword: 'Password does not meet requirements',
		},
		record: {
			saved: 'Record saved',
			deleted: 'Record deleted',
			recordsUpdated: 'Records updated',
		},
		config: {
			saved: 'Settings saved',
			blockedFieldsMessage: 'Locked parameters set in server config',
			modules: 'Modules',
		},
		mail: {
			title: 'Mail',
			user: 'Mailbox name',
			pass: 'Password',
			host: 'SMTP server address, with TSL or SSL support',
			port: 'SMTP server port',
		},
		placeholder: {
			search: 'Search...',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Email',
			sendTestEmail: 'Send test email',
			sended: 'Email sent',
			hunt: 'mail@example.com',
			sent: 'Email sent',
		},
		sign: {
			in: 'Authorization',
			out: 'Exit',
		},
		password: {
			changed: 'Password changed',
			hint: 'Password...',
			field: 'Password',
			input: 'Enter password',
			repeat: 'Confirm the password',
			newPasswordHint: 'Leave this field empty if you don�t want to change password',
			empty: 'Enter password in both fields',
			noMatch: `Passwords don't match`,
			match: 'Passwords match',
			new: 'New password',
			validation: {
				minLength: 'Minimum password length: {{length}}',
				requireNumber: 'Digits required',
				requireUppercase: 'Uppercase characters required',
				requireLowercase: 'Lowercase characters required',
				requireSpecialChars: 'Special characters required',
				passwordOld: 'Enter current password',
			},
			old: 'Current password',
		},
		editor: {
			enterURL: 'Enter URL:',
		},
		document: {
			title: 'Document',
			new: 'New document',
			plural: 'Documents',
			all: 'All documents',
			moduleTitle: 'Workflow',
			search: 'Filter + name search',
			total: 'Total documents',
			totalSum: 'Sum',
			card: {
				name: 'Name',
				number: 'Number',
				type: 'Type',
				status: 'Status',
				contragents: 'Counterparties',
				labels: 'Labels',
				responsible: 'Responsible',
				totalSum: 'Sum',
				project: 'Project',
				addName: 'Enter document title',
				addNumber: 'Enter number',
				addSum: 'Enter sum',
				clientNotFound: 'Client not found',
				tabs: {
					info: 'General information',
					projects: 'Projects',
					orders: 'Orders',
					payments: 'Payments',
					members: 'Members',
				},
				startDate: 'Date of the document',
				endDate: 'Closing date',
				order: 'Order',
				StartDate: 'Document date',
				EndDate: 'Closing Date',
			},
			emptyOrders: 'No orders found for this document',
			private: 'Privacy',
			notFound: 'Document not found',
			document_one: 'document',
			document_few: 'documents',
			document_many: 'documents',
			calendarHint: 'Documents without assigned closing date',
		},
		documentType: {
			title: 'Document Type',
			plural: 'Document types',
			new: 'New document type',
			delete: 'Delete type',
		},
		documentStatus: {
			title: 'Document Status',
			plural: 'Document statuses',
			new: 'New document status',
			delete: 'Delete status',
		},
		category: {
			new: 'New category',
			total: 'Total categories',
			title: 'Category',
			plural: 'Categories',
			name: 'Name',
			parent: 'Parent category',
			priority: 'Priority',
			priorityHint: 'The higher the priority value, the higher the category is displayed in list',
		},
		project: {
			isLimitedTaskPropertiesHint: 'All custom fields by default. Only selected in limit mode',
			isLimitedTaskProperties: 'Limited custom task properties',
			taskCustomFields: 'Additional task fields',
			noListsMessage: 'Project {{projectName}} has not added task statuses',
			goBack: 'Go back to projects',
			select: 'Select project from list',
			creator: 'Project creator',
			title: 'Project',
			plural: 'Projects',
			createNew: 'Create new project',
			created: 'Project created:',
			addRole: 'Add participant',
			addTask: 'Add task',
			dueDate: 'Project deadline',
			labels: 'Project labels',
			name: 'Project name',
			addName: 'Enter project name',
			description: 'Project description',
			projectList: 'Project status:',
			projectListShort: 'Condition',
			tasks: 'Tasks',
			lists: 'Task states',
			members: 'Members',
			history: 'Project history',
			info: 'Information',
			deleteMsg: 'You are going to delete project! Are you sure?',
			notFound: 'Project not found',
			listMode: 'List',
			kanbanMode: 'Kanban',
			count: 'Projects',
			tasksCount: 'Tasks',
			openedCount: 'Open',
			closedCount: 'Closed',
			filterHint: 'Search by id, name',
			search: 'Project Search',
			total: 'Total projects',
			mode: 'View',
			all: 'All projects',
			active: 'Active',
			closed: 'Closed',
			sortByUpdated: 'Recently Updated',
			sortByNew: 'New ones first',
			owner: 'Project created by ',
			searchByMember: 'Search by member',
			delete: 'Delete project',
			startDate: 'Project start date',
			new: 'New Project',
			taskList: 'Task states',
			memberExist: 'User already added to project. Please refresh page',
			mine: 'My projects',
			tasksFilter: {
				startDate: 'Start date',
				endDate: 'End date',
			},
		},
		client: {
			title: 'Counterparty',
			plural: 'Counterparties ',
			create: 'New counterparty',
			personName: 'Contact Person',
			personRole: 'Role',
			contacts: 'Contacts',
			addContact: 'Add',
			orders: `Counterparty's orders`,
			removeContact: 'Remove contact?',
			removeContactWarning:
				'Remove contact from counterparty?\nthis will also remove this contact from all orders of this counterparty.\nOrders with this contact:',
			noContacts: 'No contacts found',
			onlyAvailable: 'Counterparty contacts only',
			total: 'Total counterparties',
		},
		contact: {
			searchHint: 'Search by Full name / username / email',
			new: 'New Contact',
			emptyName: 'Fill at least one field (last name / first name / middle name / login)',
			add: 'Add contact',
		},
		person: {
			title: 'Person',
			plural: 'Persons',
			create: 'New person',
			choose: 'Choose a person',
		},
		payment: {
			title: 'Payment',
			plural: 'Payments',
			contragent: 'Counterparty',
			date: 'Payment date',
			total: 'Amount',
			payer: 'Payer',
			recipient: 'Recipient',
			vat: 'VAT sum',
			vatRate: 'VAT rate',
			payerText: 'Sender text',
			recipientText: 'Recipient text',
			selectProject: 'Select project',
			selectPayer: 'Select sender',
			selectRecipient: 'Select recipient',
			selectBankAccount: 'Select account',
			selectCashFlow: 'Select article',
			selectCurrency: 'Select currency',
			selectContragent: 'Select counterparty',
			selectDirection: 'Select direction',
			allContragents: 'All counterparties',
			allDirections: 'All directions',
			selectedContragents: 'Selected counterparties',
			selectedBankAccounts: 'Selected accounts',
			selectedCashFlows: 'Selected articles',
			selectedProjects: 'Selected projects',
			selectedDirections: 'Directions selected',
			direction: 'Direction',
			bankAccount: 'Account',
			recipientBankAccount: 'Recipient bank account',
			payerBankAccount: 'Payer bank account',
			number: 'Number',
			paymentId: 'Identifier',
			purpose: 'Purpose',
			priority: 'Priority',
			responsiblePerson: 'Responsible person',
			providerStatus: 'Status of originator',
			KBKindicator: 'KBK indicator',
			okato: 'OKATO',
			baseIndicator: 'Base indicator',
			periodIndicator: 'Period indicator',
			numberIndicator: 'Number indicator',
			dateIndicator: 'Date indicator',
			typeIndicator: 'Type indicator',
			budgetTransfer: 'Budget transfers',
			paid: 'Paid',
			contract: 'Contract',
			allBankAccounts: 'All accounts',
			allCashFlows: 'All articles',
			allProjects: 'All Projects',
			income: 'Arrival',
			costs: 'Expense',
			transfer: 'Transfer',
			turnover: 'Turnover',
			profit: 'Profit',
			addIncome: 'Add Income Operation',
			addCosts: 'Add expense operation',
			addTransfer: 'Add transfer',
			add: 'Add new operation',
			edit: 'Operation card',
			transferCashFlow: 'Transfer between accounts',
			plan: 'Planned',
			fact: 'Actual',
			balance: 'Balance',
			all: 'Everything',
			bankAccountFrom: 'From account',
			bankAccountTo: 'To account',
			billingDate: 'Payment date',
			splitTotal: 'Split Payment',
			delete: 'Delete operation',
			costsTotal: 'Total costs',
			incomeTotal: 'Total revenue',
		},
		contract: {
			title: 'Contract',
			plural: 'Agreements',
			date: 'Date',
			contragent: 'Counterparty',
			organization: 'Company',
			name: 'Title',
			number: '#',
			contractType: 'Type',
			conventionalUnit: 'Conventional unit ',
		},
		projectRole: {
			title: 'Project',
			plural: 'Project roles',
			short: 'Role',
			new: 'New project role',
			choose: 'Choose project role',
			delete: 'delete role',
		},
		user: {
			create: 'New user',
			allExecutors: 'All responsibles',
			allUsers: 'All users',
			selectedExecutors: 'Responsibles selected',
			selectedUsers: 'Users selected',
			allAuthors: 'All creators',
			selectedAuthors: 'Creators selected',
			allMembers: 'All members',
			selectedMembers: 'Members selected',
			recoverEmailSended: 'Password recovery email sent to specified address',
			title: 'User',
			plural: 'Users',
			new: 'New user',
			phone: 'Phone',
			email: 'E-mail',
			emptyEmail: 'Enter email',
			invalidEmail: 'Invalid email',
			emailVerified: 'Email confimed',
			name: 'Login',
			loginOrEmail: 'Login / Email',
			password: 'Password',
			firstName: 'Name',
			lastName: 'Last name',
			middleName: 'Middle name',
			profile: 'Profile',
			leftComment: 'left comment',
			createdTask: 'created task',
			telegram: 'Telegram ID',
			deletedUser: 'Deleted user',
			emailAlreadyExists: 'User with specified email already exists',
			all: 'All users',
			confirmSelectBlockedUser: 'User blocked. Are you sure?',
			isOnline: 'Status',
			lastAuthDate: 'Last login date',
			isBlocked: 'Blocked',
			block: 'Block',
			unlock: 'Unlock',
			transferUser: {
				title: 'Selected user is projects and tasks creator',
				isTransfer: 'Choose a successor',
				onTransfer: 'Proceed with delete',
			},
			statusesTitle: 'Statuses user',
			statusTitle: 'Status',
			lastActivity: 'Last seen',
		},
		profile: {
			enterName: 'Enter name',
			enterLastName: 'Enter last name',
			enterMiddleName: 'Enter middle name',
			phone: 'Phone',
			enterId: 'Enter ID',
			title: 'Profile settings',
			addAvatar: 'Add photo',
			selectPicture: 'Select photo',
			blocked: 'Are you sure you want to block this user?',
			notBlocked: 'Are you sure you want to unlock this user?',
			blockedStatus: 'Block reason',
			protectionNoActivity: 'Inactivity block protection',
		},
		role: {
			title: 'Role',
			plural: 'Roles',
			new: 'New role',
			name: 'Title',
			description: 'Description',
		},
		acl: {
			title: 'Access',
			plural: 'Access',
			create: 'New permission',
			principalType: 'Access subject',
			app: 'Application',
			role: 'Role',
			user: 'User',
			accessType: 'Action',
			read: 'Read',
			write: 'Write',
			execute: 'Execute',
			all: 'All',
			principalId: 'Subject',
			model: 'model',
			permission: 'Permission',
			allow: 'allow',
			deny: 'deny',
			property: 'Property',
			search: 'Search',
			new: 'New permission',
		},
		roleMapping: {
			title: 'Role mapping',
			plural: 'Role mappings',
			create: 'New role mapping',
		},
		projectMembers: 'Project members ',
		projectMember: {
			title: 'Member',
			plural: 'Members',
			create: 'New member',
			choose: 'Select project role',
			same: 'Project member already exists',
			comment: 'Comment',
			search: 'Search',
		},
		labels: 'Tags',
		label: {
			title: 'Tag',
			plural: 'Tags',
			all: 'All tags',
			new: 'New tag',
			delete: 'Delete tag',
		},
		tasks: 'Tasks',
		tasksToolbar: {
			relations: {
				user: 'Responsible',
				owner: 'Creator',
				members: 'Member',
				selected: 'selected: {{countRelations}}',
			},
		},
		task: {
			additionalTaskFields: 'Additional task fields',

			notification: {
				youResponsibleThisTask: 'You have been assigned to a task',
				youMentionThisTask: 'You have been mentioned in this task',
				youResponsible: 'You have been assigned to a task ',
				youMention: 'You have been mentioned to a task ',
				taskChecklistUpdate: 'Checklists updated',
				taskCommentUpdate: 'Comments updated',
			},
			deleteDescription: 'Are you sure you want to delete this task? It will not be possible to undo this action ',
			ownerNotification: 'You have been assigned to a task',
			overDue: 'Overdue tasks',
			mine: 'My tasks',
			mineOverdueTasks: 'My overdue tasks',
			overview: 'Overview',
			otherLists: 'Other lists',
			any: 'Any',
			title: 'Task',
			plural: 'Tasks',
			create: 'New task',
			delete: 'Delete task',
			confirmDelete: 'Please confirm deleting',
			name: 'Task title',
			description: 'Task description',
			changeDescription: 'Change description',
			createDescription: 'Add description',
			priority: 'Priority',
			person: 'Assigned to',
			startDate: 'Start date',
			dueDate: 'Deadline',
			assignedTo: 'Assigned',
			notAssigned: 'Not assigned',
			actions: 'Task actions',
			labels: 'Task tags',
			updatedAt: 'Updated',
			closed: 'Closed',
			active: 'Active',
			status: 'Status',
			boardList: 'Task status',
			all: 'All',
			allAuthor: 'Any creator',
			private: 'Private',
			subject: 'task',
			noProject: 'Task without project',
			sortByUpdated: 'New first',
			total: 'Total tasks',
			notFound: 'Task not found',
			addDescription: 'Add task description',
			assignedToMe: 'Assigned to me',
			addName: 'Enter task title',
			iAuthor: 'Me creator',
			meTaskMember: 'Me task member',
			selectedTaskMembers: 'Members selected:',
			me: 'Me',
			planTime: 'Time planned',
			factTime: 'Actual time',
			createdAt: 'Create date',
			closedAt: 'Close date',
			members: 'Members',
			responsible: 'Assigned to',
			task_one: 'task',
			task_few: 'tasks',
			task_many: 'tasks',
			calendarHint: 'Tasks without deadline',
		},
		history: {
			sectionTitle: 'History',
			title: 'Task history',
			userHistory: 'User history',
			created: 'created task',
			comments: 'Comments',
			leftComment: 'left comment',
			updated: 'updated',
			appointed: 'changed task assignment to',
			appointedSelf: 'appointed himself responsible for task',
			appointedNull: 'removed task assignment',
			status: 'changed task status',
			priority: 'changed task priority',
			private: 'set task private',
			public: 'set task public',
			startDate: 'set task start date to',
			dueDate: 'set task deadline to',
			initial: 'original version',
			prev: 'previous version',
			updatedTo: 'updated version',
			deleteFile: 'deleted file(s)',
			empty: 'emtpy',
			nulled: 'value deleted',
			addLabel: 'added tag',
			removeLabel: 'deleted tag',
			removeDueDate: 'removed deadline',
			removeStartDate: 'removed task start date',
			taskMemberAdded: 'added task member ',
			projectMemberAdded: 'added project member ',
			taskMemberSelfAdded: 'added himself as task member',
			projectMemberSelfAdded: 'added himself as project member',
			taskMemberRemoved: 'removed from task members ',
			projectMemberRemoved: 'removed from project members ',
			taskMemberSelfRemoved: 'removed himself from task members',
			projectMemberSelfRemoved: 'removed himself from project members',
			memberStatus: 'changed member role',
			memberStatusSelfChange: 'changed self role',
			to: 'to',
			planTime: 'changed task planned time',
			factTime: 'changed task actual time',
			task: {
				project: 'changed project to',
				checklistPosition: 'changed checklist item',
				on: 'to',
			},
			project: {
				created: 'created project',
				startDate: 'changed project start date to',
				endDate: 'changed project end date to',
				title: 'Project history',
				name: 'changed project title to',
				status: 'changed project status to',
				description: 'changed project description to',
				contragent: 'changed counterparty to',
				taskStatus: 'changed task status {{task}} to',
				appointedSelf: 'appointed himself responsible for task {{task}}',
				appointed: 'assigned task {{task}} to',
				appointedNull: 'removed task {{task}} assignment',
			},
			productionOrder: {
				title: 'Work order history',
				create: 'created work order',
				updated: 'updated',
				technologist: 'changed work order technologist to',
				status: 'changed work order status to',
				name: 'changed work order title to',
				comment: 'changed comment to',
				amount: 'changed amount to',
				startDate: 'changed production date to',
				endDate: 'changed end date to',
				measure: 'changed unit of measure to',
				warehouseTo: 'changed finished products warehouse to',
				nomenclature: 'changed nomenclature to',
				techMap: 'changed process chart to',
				techMapReset: 'cleared process chart and processes',
				sendToWarehouse: 'sent finished products to warehouse',
				cancel: 'cancelled work order',
			},
			deltaPopup: {
				old: 'Before',
				new: 'After',
			},
			notFound: 'Records not found',
			productionOrderProcess: {
				title: 'Processes history',
				create: 'created process',
				executor: 'assigned process "{{processName}}" to',
				money: 'changed process "{{processName}}" cost',
				warehouse: 'changed process warehouse "{{processName}}" to',
				equipment: 'changed process equipment "{{processName}}" to',
				workshop: 'changed process workshop "{{processName}}" to',
				status: 'changed process status "{{processName}}" to',
				readyTime: 'changed process readiness time "{{processName}}" to',
				factTime: 'changed process actual time "{{processName}}" to',
				updated: 'updated process "{{processName}}" data',
				process: 'selected process',
				deleted: 'deleted process',
				empty: '[empty]',
				deletedRecord: '[deleted]',
			},
			productionOrderProcessPosition: {
				title: 'Process positions history',
				create: 'created material for process "{{processName}}"',
				updated: 'updated material data "{{materialName}}" of "{{processName}}" process',
				material: 'changed process "{{processName}}" material to',
				quantity: 'changed quantity per product of "{{materialName}}" material "{{processName}}" process',
				nomenclatureConsumption: 'created expense of "{{materialName}}" material "{{processName}}" process',
				calibrationQuantity: 'changed adjustment of "{{materialName}}" material "{{processName}}" process',
				consumption: 'changed write-off amount of "{{materialName}}" material "{{processName}}" process',
				deleted: 'deleted material of "{{processName}}" process',
				empty: '[empty]',
				deletedRecord: '[deleted]',
			},
			dialog: {
				close: 'closed order',
				open: 'opened order',
				spamOn: 'changed order to spam',
				spamOff: 'removed order from spam',
				deleted: '[deleted]',
				task: 'created task',
				order: 'created order',
				document: 'created document',
				messages: 'Chat',
				status: 'changed status',
				employee: 'created employee',
			},
			notMessages: 'No messages',
		},
		boardLists: 'Task statuses',
		boardList: {
			title: 'Task status',
			plural: 'Task statuses',
			new: 'New task status',
			name: 'Task status title',
			selectListToMove: 'Select tasks destination',
			moveTo: 'Move tasks to',
			choose: 'Select list',
			movingTasksTo: 'Moving tasks into list: ',
			closing: 'Closing',
			closingShort: 'Closing',
			all: 'All statuses',
			selected: 'Statuses selected',
			projectDefault: 'Default',
			default: 'Default status',
			delete: 'Delete status',
			deleteInvalidMsg: 'Unable to delete status while tasks in selected status present',
			search: 'Search status',
		},
		projectLists: 'Project statuses',
		projectListsLabels: {
			beginEdit: 'Edit',
			endEdit: 'Save',
			countTasks: 'Tasks',
		},
		projectList: {
			title: 'Project status',
			plural: 'Project statuses',
			new: 'New project status',
			name: 'Status title',
			closing: 'Closing',
			default: 'Default',
			delete: 'Delete status',
			all: 'All statuses',
			selected: 'Statuses selected',
			deleteInvalidMsg: 'Unable to delete status while projects in selected status present',
		},
		taskLabel: {
			title: 'Task tag',
			labels: 'Task tags',
			plural: 'Task tags',
			create: 'New task tag',
		},
		file: {
			uploadFile: 'Upload file',
			uploadImage: 'Upload image',
			uploadStart: 'Upload started',
			uploadError: 'Upload error',
			fileTooBig: 'File too large',
			uploading: 'uploading',
			uploadComplete: 'upload complete',
			notSelected: 'no file selected',
			willBeUploaded: 'Will be uploaded',
			wrongType: 'Incorrect file type',
			moveOrSelectFile: 'Drag over or select file',
		},
		validate: {
			empty: 'Empty value',
		},
		kanban: {
			searchHint: 'Search by number, title, name, tag',
			orderByPriority: 'By default',
			orderByUpdate: 'By update',
			orderByDueDate: 'By deadline',
			orderByUpdateInfo: 'When sorted by update\ntask moving up and downwards allowed to the top only',
			orderByDueDateInfo: 'When sorted by deadline\ntask moving up and downwards is not allowed',
		},
		gantt: {
			showTasks: 'Show task list',
			start: 'From',
			end: 'To',
			duration: 'Duration',
			day: 'day',
			days: 'days',
			day234: 'days',
			noTasksWithDeadlines: 'There are no tasks with filled deadlines',
		},
		analytics: {
			title: 'Analytics',
			plural: 'Analytics',
			projects: 'By projects',
		},
		comment: {
			confirmTitle: 'Cancel',
			confirmDescription: 'Entered changes will be abandoned. Please confirm',
			confirmButtonText: '��������',
			delete: 'Delete comment',
			title: 'Comment',
			postTitle: 'Post comment',
			placeholder: 'Comment text',
			post: 'Post',
			confirmDelete: 'Delete comment?',
		},
		filter: {
			all: 'all',
			none: 'none',
			equals: 'equals',
			notEquals: 'not equal',
			greater: 'greater',
			greaterOrEqual: 'greater or equal',
			less: 'less',
			lessOrEqual: 'less or equal',
			contains: 'contains',
			doesNotContain: 'not contain',
			startsWith: 'starts with',
			endsWith: 'ends with',
			plural: 'Filters',
			apply: 'Apply',
			reset: 'Reset all',
		},
		ui: {
			theme: 'Theme',
			themes: {
				default: 'Default',
				dark: 'Dark',
			},
		},
		sales: {
			title: 'Sales',
		},
		feature: {
			title: 'Feature',
			plural: 'Features',
			create: 'Create feature',
			dataType: 'Data type',
			featureType: 'Feature type',
			values: 'Values',
			string: 'Text',
			number: 'Number',
			boolean: 'Yes / No',
			list: 'List',
			required: 'Required',
			weight: 'Order',
			products: 'Production types',
			product: 'Production',
			variant: 'Product variation',
			null: '-',
			valuesPlural: 'Values',
		},
		priceList: {
			title: 'Pricelist',
			plural: 'Pricelists',
			description: 'Pricelist description',
			name: 'Pricelist title',
			start: 'Active date',
			end: 'End date',
			period: 'Active period',
			positions: 'Positions',
			total: 'Pricelists total',
			catalog: 'Catalogue',
			betaTitle: 'Pricelist beta',
			new: 'New pricelist',
			create: 'New pricelist',
			info: 'Information',
			copy: 'Copy pricelist',
		},
		priceCategory: {
			title: 'Pricelist category',
			plural: 'Pricelist categories',
			create: 'New category',
			features: 'Features',
			priceCalculation: 'Price calculation',
			positions: 'Positions',
			moveTo: 'Move to category',
			confirmMove: 'Confirm move',
			root: 'Root',
			name: 'Title',
			new: 'New category',
			enterName: 'Enter title',
		},
		product: {
			select: 'Select product from list',
			addCategories: 'Add categories to pricelist',
			title: 'Product',
			plural: 'Products',
			create: 'New product',
			info: 'General information',
			name: 'Title',
			description: 'Description',
			type: 'Product type',
			category: 'Category',
			variant: 'Variation',
			nomenclatures: 'Nomenclature',
			createVariant: 'Create variation',
			createNomenclature: 'Create nomenclature',
			deleteVariant: 'Delete variation',
			nomenclaturesCount: 'Nomenclatures',
			confirmDeleteVariant: 'Delete variation?',
			variantImage: 'Variation image',
			amountRanges: 'Number ranges for pricing',
			features: 'Features',
			new: 'New product',
			goBackToPriceList: 'Return to pricelist',
			addDescription: 'Add product description',
			amountRange: {
				errorAdjacentRange: 'Values don�t match with nearby ranges',
				errorCurrentRange: 'Check current range values',
			},
			techMap: {
				title: 'Process charts',
				create: 'Create process chart',
				goToTechMap: 'Goto process chart',
			},
			reserved: 'Reserved',
			warehouse: 'Warehouse',
			sendToProduction: 'Send to production',
			priceList: 'Pricelist',
			nomenclature: 'Nomenclature',
		},
		productCategory: {
			title: 'Product category',
			plural: 'Product categories',
			add: 'Add category',
			addBefore: 'Add before',
			addAfter: 'Add after',
			addChild: 'Add sub-category',
		},
		pricePosition: {
			title: 'Pricelist position',
			plural: 'Pricelist positions',
			amount: 'Amount',
			from: 'from',
			to: 'to',
			amountFrom: 'Amount from',
			amountTo: 'Amount to',
			price: 'Cost',
			priceForOne: 'Cost per piece',
			copyPrice: 'Copy',
			total: 'Total amount',
			name: 'Position title',
			nameShort: 'Title',
		},
		position: {
			add: 'Add position',
			delete: 'Delete position',
			deleted: 'Position deleted',
			addMaterial: 'Add material',
		},
		userLogs: {
			create: 'created',
			update: 'updated',
			delete: 'deleted',
			models: {
				task: 'Task',
				project: 'Project',
				order: 'Order',
				productionprocess: 'Process',
				nomenclature: 'Nomenclature',
				nomenclaturemovement: 'Movement',
				warehouse: 'Warehouse',
				productionorder: 'Work order',
				productionworkshop: 'Workshop',
				processequipment: 'Equipment',
				material: 'Materials',
				techmap: 'Process chart',
				document: 'Documents',
				contragent: 'Counterparty',
				user: 'User',
				role: 'Role',
				acl: 'Permission',
			},
			modules: {
				management: 'Management',
				sales: 'Sales',
				finance: 'Finance',
				storehouse: 'Warehouse',
				manufacture: 'Production',
				documents: 'Document flow',
				contragents: 'Counterparties',
				admin: 'Adminstration',
			},
		},
		process: {
			title: 'Process',
			add: 'New process',
			plural: 'Processes',
			position: 'Positions',
			new: 'New process',
			addName: 'Enter process title',
			noPositionCategory: 'Position category not specified',
			selectPosition: 'Select order position',
			delete: 'Delete process',
			addDescription: 'Add process description',
			newProcess: 'New process',
			workshop: {
				title: 'Workshop',
				plural: 'Workshops',
				new: 'Save entry before adding warehouse',
				search: 'Workshop search',
				warehouseLabel: 'Warehouses',
				placeholder: 'Select from list',
				warehouseCount: 'Warehouses',
			},
			targetWorkshop: 'Destination workshop',
			equipment: {
				create: 'New equipment',
				title: 'Equipment',
				invNumber: 'Inventory number',
				selectWorkshops: 'Select workshop',
				selectedWorkshops: 'Workshops selected:',
			},
			operatorsNumber: 'Operators',
			adjustmentsNumber: 'Adjustments',
			workingTime: 'Working time',
			addMaterial: 'Add material',
			readyTime: 'Readiness time',
			factTime: 'Actual time',
			saved: 'Process saved',
			deleted: 'Process deleted',
		},
		processEquipment: {
			title: 'Equipment',
			count: 'Equipment count',
			new: 'New equipment',
		},
		material: {
			consumption: 'Material written-off from warehouse',
			transfered: 'Material transferred',
			measure: 'Production units',
			delete: 'Delete material',
			deleted: 'Material deleted',
			all: 'All materials',
			add: 'New material',
			group: 'Material group',
			title: 'Material',
			workshop: 'Workshop',
			position: 'Positions',
			plural: 'Materials',
			name: 'Title',
			feature: 'Feature',
			format: 'Format',
			units: 'Unit',
			copiesNumber: 'Amount',
			adjustmentsNumber: 'Amount for adjustment',
			totalNumber: 'Total amount',
			count: 'Material amount',
			selectPosition: 'Select order position',
			noPositionCategory: 'Position category not specified',
			type: {
				add: 'New material type',
				title: 'Material type',
				plural: 'Material types',
				materials: 'Materials',
				new: 'Save entry before adding material',
			},
			remains: 'Material balance',
			errors: {
				notExist: 'Material with ID specified not found',
			},
		},
		technologyRoadmap: {
			title: 'Process chart',
		},
		order: {
			totalShort: 'Sum',
			prepaidValue: 'Paid in advance',
			name: 'Order title',
			categoriesAndAmount: 'Category - quantity',
			categoryNames: 'Category',
			categoryAmounts: 'Quantity',
			delete: 'Delete order',
			newNameOrder: 'Enter order title',
			dueDate: 'Deadline',
			inputInvoiceNumber: 'Enter invoice number',
			inputContractNumber: 'Enter contract number',
			copied: 'Order copied',
			descrName: 'Order description',
			amount: 'Amount',
			new: 'New order',
			report: 'Report',
			document: 'Document',
			editReport: 'Edit report',
			downloadReport: 'Download report',
			count: 'Orders',
			noOrders: 'Orders not found',
			category: 'Category',
			defaultListError: 'Default order status not specified',
			copy: 'Copy order',
			garant: 'Guarantee',
			addGarant: 'add guarantee',
			getprepaid: 'Advance received',
			title: 'Order',
			plural: 'Orders',
			all: 'All orders',
			number: 'Number',
			n: '#',
			description: 'Description',
			comment: 'Comment',
			updatedAt: 'Order updated',
			info: 'Order information',
			infoShort: 'Info',
			positions: 'Positions',
			positionsCount: 'Postions',
			total: 'Total amount',
			totalAndDebt: 'Total amount with fines',
			price: 'Cost',
			client: 'Counterparty',
			selectClient: 'not selected',
			invoiceNumber: 'Invoice',
			invoiceDate: 'Invoice date',
			contractNumber: 'Contract',
			contractDate: 'Contract date',
			paidStatus: 'Payment status',
			paid: 'Paid',
			notPaid: 'Not paid',
			status: 'Order status',
			manager: 'Manager',
			addDescription: 'Add description',
			addComment: 'Add comment',
			startDate: 'Handover date',
			endDate: 'Issue date',
			startDateFrom: 'Start date, from',
			startDateTo: 'Start date, to',
			endDateFrom: 'Issue date, from',
			endDateTo: 'Issue date, to',
			mode: 'Mode',
			priceFrom: 'Cost, from',
			priceTo: 'Cost, to',
			confirmClientChange: 'Changing counterparty will cause deleting previous counterparty�s contacts from selected order',
			gotoClient: 'Goto counterparty',
			removeContact: 'Remove contacts from order?',
			searchHint: 'Search by order, counterparty, manager, invoice',
			prepaid: 'Advance payment',
			passport: 'Order passport',
			technologyRoadmap: 'Process chart',
			dueDatePaid: 'Payment term',
			datePaid: 'Actual payment date',
			percentDebt: 'Fine per day (%)',
			debt: 'Total fine',
			history: {
				dueDate: 'changed order deadline',
				dueDateNull: 'removed order deadline',
				old: 'Before',
				new: 'After',
				editPositionLog: 'changed order position',
				addPositionLog: 'added order position',
				dueDatePaid: 'changed payment term',
				datePaid: 'changed actual payment date',
				percentDebt: 'changed fine per day',
				addAttachment: 'added document',
				addContactPerson: 'added contact',
				removeContactPerson: 'deleted contact',
				changeContragent: 'changed counterparty',
				addLabel: 'added tag',
				removeLabel: 'deleted tag',
				removeAttachment: 'deleted document',
				title: 'Order history',
				created: 'Created order',
				invoiceNumber: 'Changed invoice',
				invoiceDate: 'changed invoice date',
				contractNumber: 'changed contract',
				contractDate: 'changed contract date',
				paid: 'changed payment status to',
				paidYes: 'Paid',
				paidNo: 'Not paid',
				status: 'changed order status to',
				newPosition: 'added order position',
				deletedPosition: 'deleted order position',
				newWaybill: 'added waybill',
				deletedWaybill: 'deleted waybill',
				total: 'Total amount changed: ',
				appointed: 'assigned to manager: ',
				appointedSelf: 'assigned to himself',
				appointedNull: 'removed assignment',
				client: 'changed counterparty: ',
				startDate: 'changed start date:',
				endDate: 'changed end date:',
				prepaidValue: 'updated advance payment amount: ',
				prepaid: 'changed advance payment status',
				prepaidYes: 'Advance payment received',
				prepaidNo: 'Advance payment not received',
				newProcess: 'added order process',
				deletedProcess: 'deleted order process',
				newMaterial: 'added order material',
				deletedMaterial: 'deleted order material',
				deletedPositionAttachment: 'deleted order position file(s)',
				addedPositionAttachment: 'added order position file(s)',
				closeOrder: 'closed order',
				sendToProduction: 'position {{position}} sent to production',
				from: 'from order "{{orderName}}"',
			},
			waybill: {
				tabname: 'Waybill',
				name: 'Title',
				gramms: 'Sheet size',
				count: 'Sheets',
				density: 'Density',
				gaveOut: 'Issued',
				defect: 'Flaw',
				return: 'Return',
				group: 'Nomenclature group',
			},
			contragent: {
				new: 'New counterparty',
				fastCreate:
					'This form is used for simplified creation of counterparty. For full edit goto counterparty record in Counterparties section or use the link "goto counterparty"',
			},
			position: {
				fastCreate: 'Created category will be root, to create a child category use "Pricelist" menu',
			},
			cancelOrder: 'Cancel order',
			confidentCancelOrder: 'Are you sure you want to cancel order?',
			confidentCloseOrder: 'Are you sure you want to close order?',
			closeOrder: 'Close order',
			order_one: 'order',
			order_few: 'orders',
			order_many: 'orders',
			calendarHint: 'Orders without issue date',
			clearPositions: 'Clear positions',
			mine: 'My orders',
		},
		viewProjectList: {
			plural: 'Project statuses list',
		},
		billing: {
			total: 'Total with discount',
			tariff: 'Tariff',
			active: 'Active',
			stopped: 'Stopped',
			onChangeTariff: 'Change tariff',
			onChange: 'Change',
			changing: 'Changing',
			confirmChanging: 'Confirm tariff change',
			storage: 'Disk space',
			changeTariffMes: 'Payment pending',
			cancelPayment: 'Cancel payment',
			title: 'Billing',
			cost: 'Cost',
			currency: 'Currency',
			expireDate: 'Expire date',
			status: 'Status',
			usedSpace: 'Used',
			extensionCost: 'Extend',
			balance: 'Balance',
			notEnoughMoney: 'Insufficient funds, refill account',
			unknownError: 'Unknown error',
			extend: 'Refill',
			confirmQuestion: 'Funds will be debited from account. Continue?',
			payment: 'Payment',
			pay: 'Pay',
			failedPayTitle: 'Warning',
			successPayTitle: 'Congratulations',
			failedPayDescription: 'Error occured, probably insufficient funds',
			successPayDescription: 'Payment successful',
			payClose: 'Close',
		},
		orderList: {
			title: 'Order status',
			plural: 'Statuses',
			create: 'New status',
			name: 'Status title',
			default: 'Default',
			closing: 'Closing',
			roleNames: 'Roles',
			role: 'Role',
		},
		orderTechPassport: {
			bindingType: 'Binding type',
			textColorfulness: 'Text colorfulness',
			publicationFormat: 'Publication format',
			pastingColorfulness: 'Pasting colorfulness',
			cutFormat: 'Cut format',
			colorfulFlyleaf: 'Colorful flyleaf',
			formatAfterCutting: 'Format after cutting',
			coverColorfulness: 'Cover colorfulness',
			pagesNumber: 'Pages number',
			filmType: 'Film type',
			textVolume: 'Text volume',
			embossing: 'Embossing',
			pasteVolume: 'Paste volume',
			varnishing: 'Varnishing',
			additionalInformation: 'Additional information',
		},
		orderPosition: {
			discount: 'Discount',
			copy: 'Copy',
			title: 'Order position',
			add: 'Add position',
			plural: 'Order positions',
			amount: 'Quantity',
			price: 'Cost',
			priceForOne: 'Cost per piece',
			category: 'Category',
			pricePositionName: 'Position title',
			selectedPricePositionName: 'Selected position',
			save: 'Save',
			lookingForMatches: 'Searching',
			matchingPricePositions: 'Matches found',
			emptyAmount: 'Matches do not include quantity. Specify quantity to copy price',
			noMatches: 'No matches',
			noCategorySelected: 'No category selected',
			selectCategoryToEditFeatures: 'Select category to edit features',
			noFeaturesSetForCategory: 'No features set for category',
			gotoCategory: 'Goto category',
			discountPercent: 'Discount, %',
			discountDirect: 'Discount amount',
			final: 'Total cost',
			members: 'Members',
			comment: 'Comment',
			attachmentsLink: 'Attachments link',
			membersShort: 'Members',
			addMember: 'Add member',
			addParameter: 'Add parameter',
			parameters: 'Parameters',
			size: 'Size',
			unitPrice: 'Unit price',
			delete: 'Delete position',
			materialRef: 'Materials link',
			willAbleAfterSave: 'Additional data will be available after saving',
			enterDiscount: 'Enter discount',
			enterAmount: 'Enter quantity',
			enterUnitPrice: 'Enter unit price',
			enterSize: 'Enter size',
			enterPrice: 'Enter price',
			enterRef: 'Enter reference',
			addingProduction: 'Add to work order',
			deleteConfirm: 'Delete?',
			waybill: 'Waybill',
			reserved: 'Reserved',
			reservedDate: 'Reserve date',
			nomenclatureId: 'Nomenclature',
			warehouseId: 'Warehouse',
			nomenclature: {
				nameProduct: 'Product title',
			},
		},
		orderPositionParameter: {
			title: 'Order position parameter',
			plural: 'Order position parameters',
			new: 'New order position parameter',
			delete: 'Delete parameter',
		},
		orderPositionRole: {
			title: 'Order position role',
			plural: 'Order position roles',
			new: 'New order position role',
			delete: 'Delete role',
		},
		sorting: {
			title: 'Sort',
			newFirst: 'New first',
			oldFirst: 'Old first',
			priceAsc: 'Inexpensive first',
			priceDesc: 'Expensive first',
		},
		nomenclatureGroup: {
			all: 'All categories',
			title: 'Nomenclature category',
			new: 'New nomenclature category',
			delete: 'Delete category',
			plural: 'Nomenclature categories',
			parent: 'Parent category',
		},
		enterpriseData: {
			title: '1S',
			sidebar: {
				submenu: '1S-Sync',
				books: '',
				nodes: 'Nodes',
				settings: 'Settings',
				objects: 'Objects',
				history: 'Sync history',
				journal: {
					title: 'Received messages',
					header: 'Sync history',
					downloadFile: 'Download file',
					goToBackVersion: 'Rollback to version',
				},
			},
			settings: {
				title: 'Database settings',
			},
			importCategory: 'Import category into pricelist',
		},
		default: 'default',
		productionOrderStatus: {
			plural: 'Work order statuses',
			new: 'New status',
			default: 'Default',
			closing: 'Closing',
		},
		contragents: {
			plural: 'Counterparties',
			all: 'All counterparties',
			card: {
				title: 'Counterparty card',
				tabs: {
					info: 'General information',
					staff: 'Staff',
					staffNew: 'New employee',
					bank: 'Bank account details',
					bankNew: 'New bank account details',
					projects: 'Projects',
					docs: 'Documents',
					payments: 'Payments',
				},
				name: 'Title',
				phone: 'Phone',
				email: 'Email',
				legalAddress: 'Legal address',
				actualAddress: 'Actual address',
				comments: 'Comments',
				basicInfo: 'General information',
				additionalInfo: 'Additional information',
				personName: 'Full name',
				personRole: 'Position',
				inn: 'TIN',
				bic: 'BIC',
				checkingAccount: 'Bank account',
				kpp: 'KPP',
				okato: 'OKATO',
				oktmo: 'OKTMO',
				okved: 'OKVED',
				ogrn: 'OGRN',
				okpo: '����',
				myCompany: 'My company',
				bank: 'Bank',
				innExist: 'TIN already used by another counterparty',
				type: 'Counterparty type',
			},
		},
		materialType: {
			plural: 'Material types',
		},
		nomenclature: {
			materialMeasureRatio: 'Material measure ratio',
			onlyFavourites: 'Show favorites only',
			showNegativeTotal: 'Show negative balance',
			date: 'Add date',
			favourite: 'Favorite',
			material: 'Material',
			plural: 'Nomenclature',
			title: 'Nomenclature',
			position: 'Nomenclature position',
			from: 'From',
			to: 'To',
			quantity: 'Quantity',
			measure: 'Unit of measure',
			onlyNonZeroQuantity: 'Above zero balance only',
			lastBalanceUpdate: 'Updated',
			noGroups: 'No groups found',
			total: 'Total',
			delete: 'Delete nomenclature',
			new: 'Add nomenclature',
			storehouse: 'Warehouse',
			remains: 'Warehouse stock',
			group: 'Nomenclature group',
			selectNomenclature: 'Select nomenclature',
			errorName: 'Specified nomenclature exists',
			isExistsName: 'Specified nomenclature exists',
			isExistsArticle: 'Specified article exists',
			hintFeature: 'At least one nomenclature must be filled out',
		},
		storehouse: {
			title: 'Warehouse',
		},
		operation: {
			title: 'Operation',
			plural: 'Operations',
			count: 'Total operations',
			add: 'New operation',
			edit: 'Edit operation',
			notFound: 'Operations not found',
			withContragent: 'Counterparty opeartions',
			new: 'New operation',
			total: 'Total operations',
			search: 'Search by sum and description',
			warnings: {
				chooseBankAndDates: 'Please select one bank account only, and start and end export dates',
			},
			errors: {
				cantReadTextFile: 'Error reading file',
				fileWasNotSelected: 'No file selected',
			},
			successNotifications: {
				importCompleted: 'Import successful',
			},
		},
		bankAccount: {
			name: 'Title',
			title: 'Bank account',
			plural: 'Bank accounts',
			selectBankAccount: 'Select bank account',
			allMyBankAccount: 'All my bank accounts',
			notSelected: 'No bank account selected',
			bank: 'Bank',
			balance: 'Balance',
			checkingAccount: 'Bank account',
			correspondentAccount: 'Correspondent account',
			bik: 'BIC',
		},
		cashFlow: {
			title: 'Cash flow item',
			plural: 'Cash flow items',
			shortTitle: 'Item',
			allCashFlow: 'All item',
			selectCashFlow: 'Select item',
			new: 'New cash flow item',
			delete: 'Delete item',
		},
		contragent: {
			title: 'Counterparty',
			titleShort: 'Counterparties',
			plural: 'Counterparties',
			myCompany: 'My company',
			myCompanies: 'My companies',
			delete: 'Delete counterparty',
			dataAboutBoss: 'CEO',
			new: 'New counterparty',
		},
		technologist: 'Technologist',
		currency: {
			title: 'Currency',
			plural: 'Currencies',
			new: 'New currency',
		},
		available: 'Available',
		productionOrder: {
			logs: 'Activity log',
			nomenclatureMovementRecord: 'Nomenclature movement record',
			sendToWarehouse: 'Send to warehouse',
			nomenclatureProduct: 'Nomenclature product',
			nomenclature: 'Nomenclature',
			warehouseTo: 'Finished products warehouse',
			pickWarehouseTo: 'Select warehouse',
			pickNomenclature: 'Select nomenclature',
			pickProduct: 'Select product',
			removeProcesses: 'Remove all processes',
			removeProcessesMessage: 'All processes will be removed and process chart will be available to choose. Proceed?',
			pickWarehouse: 'Select warehouse',
			executorNotPicked: 'Responsible not assigned',
			pickExecutor: 'Assign responsible',
			takeFromStorehouse: 'Pick from warehouse',
			removeTechMapMessage: 'All processes and process charts will be removed. Proceed?',
			applyTechMap: 'Apply process chart',
			removeTechMap: 'Clear processes and process charts ',
			addProcess: 'Add process',
			techMapName: 'Process chart title',
			pickTechMap: 'Select process chart',
			pickMeasure: 'Select measurement unit',
			pickAmount: 'Enter quantity',
			pickName: 'Enter work order title',
			product: 'Product title',
			name: 'Work order title',
			status: 'Work order status',
			pickStatus: 'Select work order status',
			technologist: 'Technologist',
			pickTechnologist: 'Select technologist',
			menu: 'Production',
			titleShort: 'Production',
			mainInfo: 'Work order information',
			movements: 'Movement',
			new: 'New work order',
			plural: 'Work orders',
			title: 'Work order',
			workshops: 'Workshops',
			equipments: 'Equipment',
			process: 'Processes',
			materials: 'Materials',
			techMap: 'Process charts',
			warehouses: 'Warehouses',
			startDate: 'Handover date',
			endDate: 'Finish date',
			productionOrder_one: 'work order',
			productionOrder_few: 'work order',
			productionOrder_many: 'work order',
			calendarHint: 'Work orders without finish date',
			processPosition: {
				calibrationQuantity: 'Adjustment',
				calibrationQuantityHint: 'Adjustments set found\nbut adjustment disabled in settings. \nPlease contact your administrator',
			},
			mine: 'My work orders',
			comment: 'Comment',
			toolbar: {
				showCancel: 'Show cancelled',
			},
		},
		productionWorkshop: {
			create: 'New workshop',
			add: 'New workshop',
			title: 'Workshop',
			plural: 'Workshops',
			noWorkshops: 'No workshops',
			all: 'All workshops',
			selected: 'Workshops selected',
		},
		techMap: {
			forPcs: 'Process chart per unit',
			quantityShort: 'Quantity per unit',
			productName: 'Product title',
			plural: 'Process charts',
			comment: 'Comment',
			author: 'Technologist',
			title: 'Process chart',
			number: '#',
			material: 'Material',
			quantity: 'Quantity',
			money: 'Money',
			addOperation: 'Add process',
			new: 'New process chart',
			materials: 'Amount of materials',
			processes: 'Number of processes',
			equipments: 'Amount of equipment',
			delete: 'Delete process chart',
			process: {
				title: 'Process',
				delete: 'Delete process',
			},
			product: {
				create: 'Create product',
				goToProduct: 'Goto product',
			},
		},
		warehouse: {
			create: 'New warehouse',
			title: 'Warehouse',
			all: 'All warehouses',
			plural: 'Warehouses',
			add: 'New warehouse',
			address: 'Address',
			phone: 'Phone',
			dopinfo: 'Additional information',
			remove: 'Delete warehouse',
			new: 'New warehouse',
			createWarehouses: 'Create warehouse',
			warehouseTitle: 'Warehouse title',
			contragent: 'Counterparty',
			responsible: 'Assigned to',
			warehouseFrom: 'Source warehouse',
			warehouseTo: 'Destination warehouse',
			nomenclatureRemains: 'Nomenclature balance',
			selectedWarehouses: 'Warehouses selected',
			allWarehouses: 'All warehouses',
			selected: 'Warehouses selected',
			errors: {
				notExist: 'No warehouse found with specified ID',
			},
		},
		declinationOfNumber: {
			direction1: 'direction',
			direction2: 'directions',
			direction5: 'directions',
			project1: 'project',
			project2: 'projects',
			project5: 'projects',
			cashFlow1: 'item',
			cashFlow2: 'items',
			cashFlow5: 'items',
			contragent1: 'counterparty',
			contragent2: 'counterparties',
			contragent5: 'counterparties',
		},
		measure: {
			title: 'Units of measure',
			plural: 'Units',
			new: 'New unit',
			delete: 'Delete unit',
		},
		contragentType: {
			title: 'Counterparty type',
			plural: 'Counterparty types',
			new: 'New counterparty type',
			name: 'Short title',
			fullName: 'Full title',
			delete: 'Delete type',
		},
		nomenclatureBalanceAccount: {
			title: 'Balance account',
			plural: 'Balance account',
			new: 'New balance account',
			isAsset: 'Include in balance calculation',
			delete: 'Delete account',
		},
		nomenclatureMovement: {
			makeMaterialMovement: 'Transfer between warehouses',
			makeMaterialConsumption: 'Write-off material from warehouse',
			materialQuantity: 'Quantity (manufacturing units)',
			consumptionRecord: 'Expense record',
			transferRecord: 'Movement record',
			title: 'Nomenclature movement',
			all: 'Nomenclature movement count',
			new: 'New movement',
			plural: 'Nomenclature movements',
			income: 'Income',
			cost: 'Expense',
			transfer: 'Movement',
			newMovement: 'New movement',
			editMovement: 'Edit movement',
			code: '#',
			numberOfLine: 'Line #',
			total: 'Amount',
			price: 'Price',
			currency: 'Currency',
			errors: {
				emptyDate: 'Date is empty',
				emptyWarehouseTo: 'Destination warehouse is empty',
				emptyWarehouseFrom: 'Source warehouse is empty',
				emptyNomenclature: 'Nomenclature is empty',
				emptyQuantity: 'Quantity is empty',
			},
			dateFrom: 'Date, from',
			dateTo: 'Date, to',
			typeOfMovement: 'Movement type',
			order: 'Order',
			type: 'Type',
		},
		total: 'Total',
		archiveAttachments: {
			download: 'Download archive',
			preparation: 'Preparing',
		},
		betaPopover: 'This section is under development, available for evaluation purpose only',
		catalog: {
			title: 'Catalogue',
			goBack: 'Back',
			showBy: 'Show by',
		},
		systemSetting: {
			title: 'General',
			enableRegistration: 'Allow users self-registration',
			maxFileSize: 'Maximum upload filesize',
		},
		documentHistory: {
			title: 'Document history',
			created: 'created document',
			updated: 'updated',
			comment: 'comment',
			name: 'title',
			number: 'changed document number',
			total: 'changed total amount',
			startDate: 'changed document date',
			endDate: 'changed document close date',
			appointed: 'assigned document to',
			appointedSelf: 'assigned document to himself',
			appointedNull: 'removed document assingment',
			status: 'changed document status to',
			removeStartDate: 'removed document date',
			removeEndDate: 'removed document close date',
			type: 'changed document type',
			addAttachment: 'added file',
			removeAttachment: 'removed file',
			addProject: 'added project',
			removeProject: 'deleted project',
			addContragent: 'added counterparty',
			removeContragent: 'deleted counterparty',
			addLabel: 'added tag',
			removeLabel: 'deleted tag',
			private: 'set document private',
			public: 'set document public',
			memberAdded: 'added to document members ',
			memberSelfAdded: 'added himself to document members',
			memberRemoved: 'removed from document members ',
			memberSelfRemoved: 'removed himself from document members',
		},
		paymentDirection: {
			title: 'Activity',
			plural: 'Activities',
			name: 'Title',
			code: 'Code',
			new: 'New activity',
			allPaymentDirections: 'All activities',
		},
		userStatus: {
			new: 'New status',
		},
		paymentsTab: {
			empty: 'No payments found',
		},
		responsiblePersons: {
			title: 'Responsible persons',
			notify: 'User notification',
		},
		productionLabel: {
			new: 'New tag',
		},
		notification: {
			settings: 'Notification setting',
			save: 'Save settings',
			socket: {
				connectedUser: 'logged in SmartERP',
			},
			task: {
				change: 'Task update',
				label: 'Tags',
				comment: 'Comments',
				user: 'Assignment',
				description: 'Task description',
				attachment: 'Files',
			},
			emptyDefaultTechnologist: 'Empty default technologist. Please contact your administrator or assign technologist manually',
		},
		calendarRecords: {
			weekDays: {
				mon: 'mon',
				tue: 'tue',
				wed: 'wed',
				thu: 'thu',
				fri: 'fri',
				sat: 'sat',
				sun: 'sun',
			},
			hideClosed: 'Hide closed',
		},
		modelProperty: {
			additionalFields: 'Additional fields',
			priority: 'Priority',
			task: 'Custom task fields',
			plural: 'Custom fields',
			project: 'Custom project fields',
			isHidden: 'Hidden field',
			isUnique: 'Unique value',
			isRequired: 'Required field',
			hasIndex: 'Index',
			type: 'Type',
			description: 'Description',
			name: 'Title',
			code: 'Codename in English',
			new: 'Add field',
			modelName: 'Model title',
		},
		periods: {
			month: {
				january: 'january',
				february: 'february',
				march: 'march',
				april: 'april',
				may: 'may',
				june: 'june',
				july: 'july',
				august: 'august',
				september: 'september',
				october: 'october',
				november: 'november',
				december: 'december',
			},
		},
		passwordSettings: {
			title: 'Password settings',
			minLength: 'Min length',
			requireNumber: 'Digits required',
			requireUppercase: 'Uppercase chars required',
			requireLowercase: 'Lowercase chars required',
			requireSpecialChars: 'Sperial chars required',
			invalidRange: 'Value must be from {{min}} to {{max}}',
			invalidPasswordOptions: 'Enable at least one password policy',
			password: 'Password',
			passwordExpirationTime: 'Password expiry (days)',
			countSavedPasswords: 'Stored passwords to exclude match',
			countUniqueCharts: 'Minimum changed characters in password',
		},
		authenticationSettings: {
			title: 'Authentication settings',
			triesLimitBeforeCaptcha: 'Show CAPTCHA after unsuccessful login attempts',
			triesLimitBeforeBlock: 'Block user after unsuccessful login attempts',
			daysNoActivityBeforeBlocked: 'Days of inactivity until user is blocked',
		},
		reports: {
			plural: 'Reports',
			finance: {
				warnings: {
					noData: 'No report data found',
				},
				operations: 'Operations',
				abcProfit: {
					title: 'ABC analysis',
					profitShare: 'Profit share %',
					profitShareCumulative: 'Profit share cumulative %',
					groupA: 'Group A',
					groupB: 'Group B',
				},
				materialMovement: {
					title: 'Material movement',
				},
				managerialEffectiveness: {
					title: 'Manager effectiveness',
					revenue: 'Revenue',
					costprice: 'Cost price',
					profit: 'Profit margin',
					profitability: 'Cost-effectiveness',
				},
				AccountsReceivable: {
					title: 'Accounts receivable',
					duedatepaid: 'Payment date',
					currentdate: 'Current date',
					daysoverdue: 'Overdue days',
					manager: 'Manager',
				},
				salesDepartmentEfficiency: {
					title: 'Sales department effectiveness',
					indicator: 'Indicator',
					revenue: 'Revenue',
					revenueChange: 'Revenue change %',
					margin: 'Profit margin',
					marginChange: 'Profit margin change %',
					newClients: 'New clients',
					newClientsChange: 'New clients change %',
				},
				nonLiquidAssets: {
					title: 'Illiquids report',
					remain: 'Balance',
					buyingAvgPrice: 'Average purchase price',
					remainsCosts: 'Balance report',
					averageSalesPerDay: 'Average sales per day',
					willBeSoldIn: 'Will be sold in',
					onDate: 'At the date',
					days: 'days',
				},
				overconsumptionOfMaterials: {
					title: 'Overrun of raw materials report',
					orderid: 'Work order #',
					ordername: 'Work order',
					materialname: 'Material',
					quantityfororder: 'Quantity per work order',
					consumption: 'Used',
					overconsumption: 'Overrun',
				},
			},
		},
		back: 'Back',
		close: 'Close',
		productionOrderProcess: {
			equipmentId: 'Equipment',
			workshopId: 'Workshop',
			warehouseId: 'Warehouse',
			executorId: 'Assigned to',
			processId: 'Process',
			money: 'Cost',
			readyTime: 'Readiness time',
			factTime: 'Actual time',
		},
		productionOrderProcessPosition: {
			materialId: 'Material',
			quantity: 'Quantity per product',
			calibrationQuantity: 'Adjustment',
			consumption: 'Write-off quantity',
		},
		mainDashboard: 'Brief',
		byFilter: 'By filter',
		manualLocking: 'Blocked by "{{userShort}}" on {{dateBlocked}}',
		video: 'Video',
		support: 'Support',
		socialNetwork: {
			title: 'Social',
			vk: {
				title: 'OAuth VK ID',
				vkApplicationId: 'Application ID',
				vkClientSecret: 'Client secret',
			},
			yandex: {
				title: 'OAuth Yandex ID',
				yandexApplicationId: 'Client ID',
				yandexClientSecret: 'Client secret',
			},
			google: {
				title: 'Google OAuth',
				googleApplicationId: 'Client ID',
				googleClientSecret: 'Client secret',
			},
			mail: {
				title: 'OAuth Mail',
				mailApplicationId: 'Application ID / Client ID',
				mailClientSecret: 'Client Secret',
			},
		},
		ERROR_SOC_NET: 'Error occured, try again later',
		globalSearch: {
			placeholder: 'Global search',
			notFound: 'Not found',
			all: 'All',
		},
		twoFactor: {
			title: 'Two factor authentication',
			'2fa': 'Two factor authentication method',
			send: {
				email: 'Two factor authentication enabled. Confirmation email sent to your address assigned',
				telegram: 'Two factor authentication enabled. Confirmation message sent to your Telegram account',
			},
			email: 'Email',
			telegram: 'Telegram',
		},
		SUCCESS_SEND_2FA: 'Message successfully sent via chosen method. Please follow message instructions',
		ERROR_TWO_FACTOR: 'Authorization error',
		ERROR_2FA_DATA: 'Invalid data',
		ERROR_USER_NOTFOUND: 'User not found',
		ERROR_2FA_TOKEN: 'Invalid token',
		dateRangePicker: {
			startDate: 'Start date',
			endDate: 'End date',
			period: 'Period',
		},
		period: {
			title: 'Period',
			start: 'Period start',
			end: 'Period end',
			select: 'Period',
			day: 'Day',
			week: 'Week',
			month: 'Month',
			quarter: 'Quarter',
			year: 'Year',
			everyday: 'Daily',
			everyweek: 'Weekly',
			everymonth: 'Monthly',
			lastDayOfMonth: 'Last day of month',
			dayOfMonth: 'day of each month',
		},
		messages: {
			title: 'Messages',
		},
		dialog: {
			plural: 'Chats',
			source: 'Source',
			nikeName: 'Alias',
			contact: 'Contact',
			lastMessage: 'Message',
			date: 'Date',
			selectCategory: 'Select category',
			isSpam: 'Spam',
			isClosed: 'Closed',
			hideSpan: 'Hide spam',
			log: 'Log',
			description: 'Desciption',
			username: 'Username',
			name: 'FCs',
			email: 'E-mail',
			phone: 'Phone',
			status: 'Status',
			countRecords: 'Number of records',
			employee: "Counterparty's employee",
			createEmployee: 'Create an employee',
		},
		postMessage: {
			placeholder: 'Message',
			emptyMessage: 'Empty message',
			send: 'Send',
			confirmDescription: 'Unsaved changes will be lost. Please confirm',
			errorSend: 'Error sending to external system',
		},
		dataType: {
			boolean: 'Yes/no',
			string: 'String',
			number: 'Number',
			date: 'Date',
			datetime: 'Date time',
			task: 'Task',
			project: 'Project',
			user: 'User',
			document: 'Document',
			order: 'Order',
		},
		dialogStatus: {
			title: 'Dialogue status',
			plural: 'Dialogue statuses',
			name: 'Name',
			color: 'Color',
			isDefault: 'New Dialogue Status',
			new: 'New status',
			notFount: 'Dialog statuses are not set',
		},
		dialogSource: {
			title: 'Source of chat',
			plural: 'Sources of chats',
			label: {
				VK: {
					login: 'Community ID',
					secretKey: 'Community Access Key',
				},
				TLG: {
					login: 'Bot name',
					secretKey: 'Bot token',
				},
				EMAIL: {
					login: 'Login',
					secretKey: 'Password',
					url: 'IMAP URL (<imapServer>:<port>)',
					urlOut: 'SMTP URL (<imapServer>:<port>)',
				},
			},
			login: 'Username',
			url: 'URL for request',
			secretKey: 'Secret key',
		},
		agGrid: {
			templates: 'Templates',
		},
		agGrid: {
			floatingFilter: 'Filters under columns',
			templates: 'Templates',
			loadTemplate: 'Load template',
			rewriteTemplate: 'Rewrite template',
			templateLoaded: 'Template "{{template}}" loaded',
			templateRewrited: 'Template "{{template}}" rewrited',
			templateSaved: 'Template "{{oldName}}" name changed to "{{template}}"',
			templateCreated: 'Template "{{template}}" created',
			templateDeleted: 'Template "{{template}}" deleted',
			namePlaceholder: 'Type name',
			settings: 'Settings',
			leftSidebar: 'Sidebar on left side',
			theme: 'Theme',
		},
	},
};
