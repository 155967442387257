 import React from 'react';
import { observer } from 'mobx-react';
import { ColorValue, Column, Pager, Table } from '@smartplatform/ui';
import { getPerPage, getLabelName } from 'client/tools';
import { ListStore, BasicList } from 'components';
import { observable } from 'mobx';
import { EXCLUDED_FIELDS } from './constants';
import store from 'client/store';
import t from 'i18n';

import './style.scss';

@observer
export class List extends React.Component {
	static defaultProps = {
		listRelations: [],
		listProperties: [],
	};

	fields = {};
	include = {};

	@observable error = null;
	constructor(props) {
		super(props);
		this.modelName = props.modelName;
		this.model = store.model[this.modelName];

		if (!this.model) {
			this.error = 'Invalid model/no read access';
		} else {
			store.ui.title = t(getLabelName('plural', this.modelName));
			const { listProperties, listRelations } = props;
			const { PROPERTIES, RELATIONS } = this.model;
			this.relations = [...listRelations];
			if (!listProperties.length) {
				// если мы не передаём проперти , то берем все за исключением технических ,объектов и гео
				const modelForeignKeys = Object.values(RELATIONS).map(({ foreignKey }) => foreignKey);
				this.properties = Object.keys(PROPERTIES).filter((prop) => {
					return (
						![...EXCLUDED_FIELDS, ...modelForeignKeys].includes(prop) &&
						!['Object', 'Geography'].includes(PROPERTIES[prop].type)
					);
				});
			} else {
				this.properties = [...listProperties];
			}

			// фильтры
			const relationFields = listRelations.map(({ relation }) => RELATIONS[relation]?.foreignKey);
			this.fields = [...this.properties, ...relationFields, 'id'];
			this.include = listRelations.map(({ relation, property, properties = [] }) => ({
				relation,
				scope: { fields: property || properties.filter(Boolean) },
			}));
		}
	}

	render() {
		if (this.error) return this.error;
		const filters = { fields: this.fields, include: this.include };

		return (
			<BasicList model={this.model} filter={filters} disabledButton={!store.isAdmin}>
				{this.properties.map((prop) => {
					const label = getLabelName(prop, this.modelName);
					const width = prop === 'id' ? 100 : undefined;
					return (
						<Column key={prop} label={label} property={prop} width={width}>
							{prop === 'color' && <ColorValue />}
						</Column>
					);
				})}
				{this.relations.map(({ relation, property, computed }) => {
					const label = getLabelName(relation, this.modelName);
					return <Column relation={relation} property={property} key={relation + property} computed={computed} label={label} />;
				})}
			</BasicList>
		);
	}
}

