import { observable } from 'mobx';
import store from 'client/store';
import { triggerSaveNotification } from 'components';
export const NOTIFICATIONS = {
	Task: ['change', 'comment', 'label', 'user', 'description', 'attachment'],
};

export const DATASOURCES = ['email', 'telegram'];

export class NotificationSettingsStore {
	@observable isLoading = true;
	@observable changedSettings = new Set();
	@observable notificationSettings = new Map();
	originalValues;

	constructor({ userId, defaultValue }) {
		this.userId = userId;
		this.defaultValue = defaultValue;
		this.init();
	}

	init = async () => {
		const settings = await store.model.Setting.find({ where: { ownerId: this.userId || null } });

		// по каждой модели(key NOTIFICATIONS) пробегаемся по всем элементам массива(value NOTIFICATIONS), которая являются ивентами
		// и по каждому датасорсу(DATASOURCES) добавляем чекбкосы. По умолчанию все они this.defaultValue(для настроек юзера false, для общих true)
		for (const model of Object.keys(NOTIFICATIONS)) {
			for (const code of NOTIFICATIONS[model]) {
				for (const dataSource of DATASOURCES) {
					this.notificationSettings.set(`notification.${model}.${code}.${dataSource}`, this.defaultValue);
				}
			}
		}

		// заполняем тем что получили из БД
		settings.forEach((setting) => {
			this.notificationSettings.set(setting.code, setting.value === 'true');
		});

		this.setOriginalValues();
		this.isLoading = false;
	};

	onChange = (key, value) => {
		this.notificationSettings.set(key, value);
		this.checkSettingChanged(key);
	};

	checkSettingChanged = (key) => {
		if (this.notificationSettings.get(key) !== this.originalValues.get(key)) {
			this.changedSettings.add(key);
		} else {
			this.changedSettings.delete(key);
		}
	};

	get isChanged() {
		return this.changedSettings.size > 0;
	}

	setOriginalValues = () => (this.originalValues = new Map(this.notificationSettings));

	onSave = async () => {
		// сохраняем только то, что изменили
		const promises = [];
		this.changedSettings.forEach((key) => {
			const value = this.notificationSettings.get(key);
			promises.push(
				store.model.Setting.create({
					value: value,
					code: key,
				})
			);
		});
		await Promise.all(promises);
		this.setOriginalValues();
		triggerSaveNotification();
		this.changedSettings.clear();
	};
}

