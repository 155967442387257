import React from 'react';
import t from 'i18n';
import { Button, LabelField, triggerSaveNotification } from 'components';
import { Select } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import './style.scss';

const ITEMS_TWO_FACTOR = ['email', 'telegram'];

@observer
export class TwoFactor extends React.Component {
	@observable twoFactor = null;
	@observable originalValue;
	@observable message = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.user = await store.model.User.findById(this.props.userId);
		await store.fetchDbServerConfig();
		this.botName = store.mergedConfig?.dataSources?.telegram?.bot;
		this.twoFactor =
			(await store.model.Setting.find({ where: { ownerId: this.user.id || null, code: 'twoFactor' } }))?.[0] ||
			new store.model.Setting({ code: 'twoFactor', ownerId: this.user.id || null });
		this.setOriginalValue();
	};

	setOriginalValue = () => {
		this.originalValue = this.twoFactor.value;
	};

	get isChanged() {
		return this.originalValue !== this.twoFactor?.value;
	}

	onChange = (value) => {
		this.twoFactor.value = value ? value : null;
	};

	onSave = async () => {
		if (!this.twoFactor.value) {
			await this.twoFactor.save();
			this.setOriginalValue();
			triggerSaveNotification();
		} else {
			try {
				const result = await store.model.User.send2FAToken({ login: this.user.email, type: this.twoFactor.value });
				if (result && result.redirect && result.token && this.twoFactor.value === 'telegram') {
					if (this.botName) {
						location.href = `https://t.me/${this.botName}?start=2fa_${result?.token}`;
					} else {
						this.message = t('ERROR_SOC_NET');
					}
				} else {
					this.message = t('SUCCESS_SEND_2FA');
				}
			} catch (error) {
				this.message = t('ERROR_SOC_NET');
				console.error('error', error);
			}
		}
	};

	computed = (value) => t(`twoFactor.${value}`);

	render() {
		return (
			<div className='wrapper-2fa-settings'>
				<h2>{t('twoFactor.title')}</h2>
				<LabelField label={t('twoFactor.2fa')} className='item-user-setting'>
					<Select items={ITEMS_TWO_FACTOR} onChange={this.onChange} value={this.twoFactor?.value} computed={this.computed} />
				</LabelField>
				{this.message && <p className='message'>{this.message}</p>}
				<Button onClick={this.onSave} disabled={!this.isChanged}>
					{t('confirm')}
				</Button>
			</div>
		);
	}
}
