import { autorun, observable } from 'mobx';
import { debounce } from 'lodash';
import { endOfDay, startOfDay } from 'date-fns';
import { MODES } from 'client/constants';
import store from 'client/store';
import { getRoleNames } from 'client/tools';
import {
	ORDER_LIST_INCLUDE,
	ORDER_KANBAN_INCLUDE,
	KANBAN_INITIAL_LIMIT,
	LOCAL_STORAGE_FIELDS,
	ORDER_TILES_INCLUDE,
	COLUMNS,
} from './constants';

import t from 'i18n';

export const ALL = 'ALL';

export default class OrdersStore {
	@observable search = '';
	@observable priceFrom = null;
	@observable priceTo = null;
	@observable orderList = [];
	@observable paid = null;
	@observable startDateFrom = null;
	@observable startDateTo = null;
	@observable mode = MODES.KANBAN;
	@observable isLoading = true;
	@observable records = [];
	@observable filters = { and: [] };
	@observable page = 1;
	@observable order = 'id desc';
	@observable kanbanLists = [];
	@observable hiddenLists = [];
	@observable perPage = null;
	@observable isEditReportSetting = false;
	@observable selectedColumnsReport = COLUMNS;
	@observable managers = [];
	@observable orderFilters = {
		manager: null,
	};
	@observable ordersCount = null;

	isReportMode = false;
	availableListIds = [];
	isManagerOrAdmin = false;
	localStorage = store.local.orders;
	kanban = {};
	//*функция которая = init из reportStore
	fetchReportRecords;

	constructor() {
		this.user = store.model.user;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.loadLocalStorage();
		autorun(() => {
			this.hiddenLists.length;
			this.isInitialized && this.saveToLocalStorage();
			this.getOrdersCount();
		});
		this.isInitialized = true;
		this.compileFilters(false);
		this.fetchManagers();
	}

	init = async () => {
		this.roles = getRoleNames();
		this.isManagerOrAdmin = this.roles.includes('admin') || this.roles.includes('manager');
		if (!this.isManagerOrAdmin) this.availableListIds = await store.model.ViewOrderList.getLists({ rolesNames: this.roles });
	};

	getOrdersCount = async () => {
		this.ordersCount = await store.model.ViewOrder.count({ ...this.filters });
	};

	selectFetchMode = async () => {
		if (this.isReportMode) {
			this.fetchReportRecords();
		} else {
			const modes = {
				[MODES.KANBAN]: this.fetchKanbanRecords,
				[MODES.LIST]: this.fetchListRecords,
				[MODES.TILES]: this.fetchTilesRecords,
			};

			modes[this.mode]?.();
		}
	};

	fetchTilesRecords = async () => {
		const tilesPageSize = 20;

		this.records = await store.model.ViewOrder.find({
			where: this.filters,
			include: ORDER_TILES_INCLUDE,
			skip: (this.page - 1) * tilesPageSize,
			limit: tilesPageSize,
			order: this.order,
		});

		this.isLoading = false;
	};

	fetchManagers = async () => {
		try {
			const managers = await store.model.ViewOrder.findUsersWithOrdersCount();
			this.managers = managers.map((manager) => new store.model.User(manager));
		} catch (error) {
			console.log(error);
		}
	};

	fetchListRecords = async () => {
		this.records = await store.model.ViewOrder.find({
			where: this.filters,
			include: ORDER_LIST_INCLUDE,
			skip: (this.page - 1) * this.perPage,
			limit: this.perPage,
			order: this.order,
		});

		this.isLoading = false;
	};

	fetchKanbanRecords = async () => {
		let listWhere = { and: [] };

		if (this.orderList.length) listWhere.and.push({ id: { inq: this.orderList.map((orderList) => orderList.id) } });

		this.kanbanLists = await store.model.ViewOrderList.find({
			where: listWhere,
			include: [
				{
					relation: 'orders',
					scope: {
						where: this.filters,
						limit: KANBAN_INITIAL_LIMIT,
						order: this.order,
						include: ORDER_KANBAN_INCLUDE,
					},
				},
			],
			order: 'priority desc',
		});
		for (const list of this.kanbanLists) {
			list.totalCount = list.orders().length;
		}

		this.reload();
		this.isLoading = false;
	};

	reload = () => this.kanban.reload && setTimeout(() => this.kanban.reload());

	doSearch = () => {
		this.compileFilters();
		this.saveToLocalStorage();
	};

	onChange =
		(prop, autoApply = true) =>
			(value) => {
				if (prop !== 'page' && this.mode !== MODES.KANBAN) this.page = 1;
				this[prop] = value;
				if (autoApply) {
					this.saveToLocalStorage();
					this.compileFilters();
				}
			};

	onChangeOrderFilters =
		(prop, autoApply = true) =>
			(value) => {
				if (prop) {
					let _value = value;
					if (prop === 'manager') _value = this.orderFilters.manager === value.id ? undefined : value.id;

					this.orderFilters[prop] = _value;
					if (autoApply) {
						this.saveToLocalStorage();
						this.compileFilters();
						this.reload();
					}
				}
			};

	setPerPage = (value) => {
		this.page = 1;
		this.perPage = value;
	};

	onDatechange =
		(prop, startDay, autoApply = true) =>
			(value) => {
				if (startDay) {
					this[prop] = value ? startOfDay(value) : null;
				} else {
					this[prop] = value ? endOfDay(value) : null;
				}

				if (autoApply) {
					this.saveToLocalStorage();
					this.compileFilters();
				}
			};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};

	onQueryUpdate = (query) => {
		const { _totalCount, ...rest } = query;
		this.query = rest;
		if (query.order) {
			this.order = query.order;
		} else {
			this.order = 'id desc';
		}

		this.compileFilters();
	};

	compileFilters = (withFetch = true) => {
		const idFilter = this.availableListIds.length ? { inq: this.availableListIds } : { neq: null };
		const where = {
			and: [{ listId: idFilter }],
		};

		const trimmed = this.search.trim();
		if (trimmed.length > 0) {
			const words = trimmed.split(' ');
			words.forEach((word) =>
				where.and.push({
					or: [
						{ id: { like: `${word}%` } },
						{ description: { ilike: `%${word}%` } },
						{ clientName: { ilike: `%${word}%` } },
						{ fio: { ilike: `%${word}%` } },
						{ username: { ilike: `%${word}%` } },
						{ contragentName: { ilike: `%${word}%` } },
						{ invoiceNumber: { ilike: `%${word}%` } },
					],
				})
			);
		}

		if (parseFloat(this.priceFrom)) {
			where.and.push({ total: { gte: parseFloat(this.priceFrom) } });
		}

		if (parseFloat(this.priceTo)) {
			where.and.push({ total: { lte: parseFloat(this.priceTo) } });
			if (!parseFloat(this.priceFrom)) {
				where.and.push({ total: { gt: 0 } });
			}
		}

		if (this.orderList.length) {
			where.and.push({ listId: { inq: this.orderList.map((orderList) => orderList.id) } });
		}

		if (this.paid !== null) {
			where.and.push({ paid: this.paid });
		}

		if (this.startDateFrom) {
			where.and.push({ startDate: { gte: this.startDateFrom } });
		}

		if (this.startDateTo) {
			where.and.push({ startDate: { lte: this.startDateTo } });
		}

		if (this.orderFilters.manager) {
			where.and.push({
				managerId: this.orderFilters.manager,
			});
		}

		this.filters = where;
		withFetch && this.selectFetchMode();
	};

	cleanFilters = () => {
		this.search = '';
		this.priceFrom = null;
		this.priceTo = null;
		this.orderList = [];
		this.paid = null;
		this.startDateFrom = null;
		this.startDateTo = null;
		this.page = 1;
		this.order = 'id desc';
		this.orderFilters.manager = null;
		this.saveToLocalStorage();
		this.compileFilters();
	};

	onModeChange = (mode) => {
		this.page = 1;
		this.mode = mode;
		this.saveToLocalStorage();
	};

	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
		store.local.save();
	};

	loadLocalStorage = () => {
		const storageKeys = Object.keys(this.localStorage);
		storageKeys.length && storageKeys.forEach((key) => (this[key] = this.localStorage[key]));
	};

	downloadReport = async () => {
		const cols = this.selectedColumnsReport
			.filter((col) => col.isSelected)
			.reduce((acc, currentCol) => {
				if (currentCol.relation) {
					acc[currentCol.relation] = currentCol.label;
				} else {
					acc[currentCol.property] = currentCol.label;
				}

				return acc;
			}, {});

		if ('categoriesAndAmount' in cols) {
			delete cols['categoriesAndAmount'];
			cols['categoryNames'] = t('order.categoryNames');
			cols['categoryAmounts'] = t('order.categoryAmounts');
		}

		const _query = {
			where: this.filters,
			fields: [...Object.keys(cols)],
			skip: (this.page - 1) * this.perPage,
			limit: this.perPage,
			order: this.order,
		};
		const params = {
			cols,
			filters: _query,
		};

		const query = `?params=${encodeURIComponent(JSON.stringify(params))}`;
		const path = `/api/ViewOrders/exportXLSX${query}`;

		const downloadLink = document.createElement('a');
		downloadLink.href = path;
		downloadLink.rel = 'noopener noreferrer';
		downloadLink.click();
		downloadLink.remove();
	};

	onToggleEditReportSetting = (value) => () => {
		this.isEditReportSetting = value;
	};
}

