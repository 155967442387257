import React from 'react';
import { observer } from 'mobx-react';
import { Button, Input } from '@smartplatform/ui';
import t from 'i18n';

@observer
export default class CardNew extends React.Component {
	constructor(props) {
		super(props);
	}

	onChange = (value) => {
		const { record, titleProperty } = this.props;
		record[titleProperty] = value;
	};

	render() {
		const { record, titleProperty } = this.props;
		return (
			<div className='wrapper-record-new'>
				<Input value={record[titleProperty]} onChange={this.onChange} />
				<div className='record-new-controls'>
					<Button size='sm' onClick={this.props.onSave}>
						{t('add')}
					</Button>
					<Button size='sm' onClick={this.props.onCancel}>
						{t('cancel')}
					</Button>
				</div>
			</div>
		);
	}
}
