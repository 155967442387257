import React from 'react';
import store from 'client/store';
import { observable } from 'mobx';
import { AgGrid, User, BoxLabel } from 'components';
import { observer } from 'mobx-react';
import t from 'i18n';
import './ordersReport.scss';
import { fioShort, renderStatus } from 'client/tools';
import { MODEL_RENDERS, getAgGridCols } from 'components/сustom-model-properties';
import { ORDERS_PATH } from 'client/modules/sales/constants';
@observer
export class OrdersReport extends React.Component {
	@observable records = [];
	@observable isLoading = true;
	@observable isInitialized = false;
	@observable perPage = 100;
	@observable page = 1;
	customFields = [];
	labels = new Set();

	constructor(props) {
		super(props);
		this.init();
	}

	fetchParams = {
		include: [
			'list',
			{ relation: 'labels', scope: { fields: ['id', 'name', 'color'] } },
			'manager',
			{ relation: 'contragent', scope: { fields: ['id', 'name'] } },
		],
		fields: ['id', 'description', 'listId', 'total', 'managerId', 'contragentId', 'dueDate', 'startDate'],
	};

	init = async () => {
		const customFields = await store.model.ModelProperty.find({ where: { modelName: 'Order' } });

		for (const customField of customFields) {
			const { type, code } = customField;
			const isRelation = MODEL_RENDERS.hasOwnProperty(type);
			customField.isRelation = isRelation;
			if (isRelation) {
				this.fetchParams.include.push(code);
				this.fetchParams.fields.push(code + 'Id');
			} else {
				this.fetchParams.fields.push(code);
			}
		}

		this.customFields = customFields;
		this.fetchRecords();
		this.getCols();
		this.isInitialized = true;
	};

	fetchRecords = async () => {
		this.records = await store.model.Order.find(this.fetchParams);
		const tempLabelsMap = new Map();
		this.records.forEach((r) => {
			for (const [key, value] of Object.entries(store.model.Order.PROPERTIES)) {
				if (value.type === 'Date') {
					r[key] = r[key] ? new Date(r[key]) : null;
				}

				r.labelsArr = r.labels().map((record) => {
					if (!tempLabelsMap.has(record)) {
						tempLabelsMap.set(record, record);
					}
					return tempLabelsMap.get(record);
				});
			}
		});

		this.isLoading = false;
	};

	getCols = () => {
		this.colDefs = [
			{
				headerName: t('id'),
				cellDataType: 'id',
				field: 'id',
			},
			{ headerName: t('order.name'), field: 'description' },
			{
				headerName: t('orderList.title'),
				field: 'list',
				valueFormatter: ({ value }) => value?.name || null,
				cellRenderer: ({ value }) => (value ? renderStatus(value) : null),
				cellDataType: 'object',
			},
			{
				headerName: t('order.manager'),
				field: 'manager',
				cellDataType: 'object',
				valueFormatter: ({ value }) => (value ? fioShort(value) : null),
				cellRenderer: ({ value }) =>
					value ? <User user={value} showBlockedIcon={false} popoverFullName={false} showOnlineStatus={false} /> : null,
			},
			{
				headerName: t('contragent.title'),
				field: 'contragent',
				cellDataType: 'object',
				valueFormatter: ({ value }) => value?.name,
			},
			{
				headerName: t('order.total'),
				field: 'total',
				cellDataType: 'currency',
				filter: 'agNumberColumnFilter',
				type: 'rightAligned',
			},
			{
				field: 'startDate',
				headerName: t('order.startDate'),
				cellDataType: 'date',
			},
			{
				field: 'dueDate',
				headerName: t('order.dueDate'),
				cellDataType: 'dateTime',
			},
			{
				headerName: t('label.plural'),
				field: 'labelsArr',
				cellDataType: 'array',
				filterParams: { valueFormatter: (params) => params.value?.name },
				valueFormatter: ({ value }) => value?.map((label) => label.name).join(', '),
				cellRenderer: ({ value: labels }) => labels?.map((label) => <BoxLabel key={label.id} label={label} />),
			},

			...getAgGridCols(this.customFields),
		];
	};

	onRowDoubleClicked = ({ data }) => {
		store.route.push({ path: `${ORDERS_PATH}/${data.id}` });
	};

	render() {
		const { perPage, records, isLoading, isInitialized } = this;
		if (!isInitialized) return null;

		return (
			<div className='order-report'>
				<AgGrid
					columnDefs={this.colDefs}
					rowData={records}
					paginationPageSize={perPage}
					loading={isLoading}
					templateCode='ORDER_REPORT'
					onRowDoubleClicked={this.onRowDoubleClicked}
				/>
			</div>
		);
	}
}

