import { NumberInput } from '@smartplatform/ui';
import React from 'react';
import './rubleInput.scss';

export const RubleInput = (props) => (
	<div className='rubble-input'>
		<NumberInput {...props} positiveOnly decimalScale={2} />
	</div>
);

