import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import './style.scss';
import Toolbar from './toolbar/Toolbar';
import { Provider } from 'mobx-react';
import CalendarRecordsStore from 'components/calendar-records/store';
import { t } from 'i18next';
import WeekMode from './WeekMode';
import { observer } from 'mobx-react';
import MonthMode from './MonthMode';
import { observable } from 'mobx';
import { AutoAnimate, Popover } from '@smartplatform/ui';
import CardRecord from './CardRecord';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Switch } from 'components';
import debounce from 'lodash/debounce';
import { getLabelParentByModel } from 'client/tools/getLabelParentByModel';
import classNames from 'classnames';

@observer
export default class CalendarRecords extends React.Component {
	@observable newItems = [];
	@observable loadingMore = false;
	@observable page = 1;
	@observable hideClosed = true;
	@observable hideSidebar = false;

	static propTypes = {
		renderItem: PropTypes.any.isRequired,
		dateProperty: PropTypes.string.isRequired,
		titleProperty: PropTypes.string.isRequired,
		model: PropTypes.any,
		beforeSave: PropTypes.func,
		initialState: PropTypes.object,
		onLocalStorageSave: PropTypes.func,
		filter: PropTypes.object,
		include: PropTypes.object,
		enableAdd: PropTypes.bool,
		closedFilter: PropTypes.bool,
	};

	static defaultProps = {
		enableAdd: true,
		closedFilter: true,
	};
	constructor(props) {
		super(props);
		this.store = new CalendarRecordsStore(this.props);
		this.init = debounce(this.init, 500, { leading: false, trailing: true });
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
			this.init();
		}
	}

	init = async () => {
		this.page = 1;
		this.newItems = [];
		await this.store.fetchRecordsByDate(this.props.filter);
		await this.store.fetchRecordsWithoutDate(this.props.filter, this.page, this.hideClosed);
	};

	addItem = (item) => this.newItems.push(item);

	onMount = (el) => {
		if (el) {
			el.addEventListener('scroll', this.onScroll);
		}
	};

	onScroll = async (e) => {
		if (this.loadingMore || this.store.recordsWithoutDate.records.length >= this.store.recordsWithoutDate.totalRecords) return;
		const bottomScroll = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight;
		if (bottomScroll < 50) {
			this.loadingMore = true;
			this.page = this.page + 1;
			await this.store.fetchRecordsWithoutDate(this.props.filter, this.page, this.hideClosed);
			this.loadingMore = false;
		}
	};

	onToggleHideClosed = (value) => {
		this.page = 1;
		this.hideClosed = value;
		this.store.fetchRecordsWithoutDate(this.props.filter, this.page, this.hideClosed);
	};

	toggleSidebar = () => (this.hideSidebar = !this.hideSidebar);

	render() {
		const { mode, handleDrop, handleDragOver, records, recordsWithoutDate } = this.store;
		const { renderItem, dateProperty, model, titleProperty, beforeSave, declinationWords, enableAdd, closedFilter } = this.props;

		const _records = [...records, ...recordsWithoutDate.records, ...this.newItems];

		const childProps = {
			records: _records,
			renderItem: renderItem,
			dateProperty: dateProperty,
			model: model,
			titleProperty: titleProperty,
			beforeSave: beforeSave,
			declinationWords: declinationWords,
			addItem: this.addItem,
			enableAdd: enableAdd,
			mode: mode,
		};

		return (
			<Provider store={this.store}>
				<div className='calendar-records'>
					<Toolbar updateRecords={this.init} />
					<div className='wrapper-content-records'>
						<div className='wrapper-toggle-left'>
							<div className={classNames('left-records', { hide: this.hideSidebar })}>
								<div className='head-tasksDL'>
									{t(`${getLabelParentByModel(model.INFO.name)}.plural`)}
									<Popover
										content={t(`${getLabelParentByModel(model.INFO.name)}.calendarHint`)}
										className='calendar-hint'
									>
										<FontAwesomeIcon icon={faWarning} />
									</Popover>
								</div>
								{closedFilter && (
									<div className='wrapper-hide-closed'>
										<span>{t('calendarRecords.hideClosed')}</span>
										<Switch onChange={this.onToggleHideClosed} value={this.hideClosed} />
									</div>
								)}
								<AutoAnimate
									className='records-without-date'
									onDrop={(e) => handleDrop(e, null)}
									onDragOver={(e) => handleDragOver(e, null)}
									ref={this.onMount}
								>
									{_records
										.filter((r) => !r[dateProperty])
										.map((record, index) => (
											<CardRecord key={index} record={record} renderItem={renderItem} />
										))}
								</AutoAnimate>
							</div>
							<div className='wrapper-toggle-btn' onClick={this.toggleSidebar}>
								<FontAwesomeIcon icon={this.hideSidebar ? faAngleRight : faAngleLeft} />
							</div>
						</div>
						<div className='right-records'>
							{mode === 'week' ? <WeekMode {...childProps} /> : <MonthMode {...childProps} />}
						</div>
					</div>
				</div>
			</Provider>
		);
	}
}
