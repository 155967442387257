import { observable } from 'mobx';
import React from 'react';
import store from 'client/store';

export default class HistoryTabsStore {
	@observable activeTab = null;
	@observable items = [];
	@observable isLoading = true;
	@observable page = 1;
	@observable loadingMore = false;
	@observable order = 'id desc';
	@observable popup = null;
	refMessagesWrapper = React.createRef();
	@observable isTabInit = false;
	@observable isInit = false;

	// Сделать сохранение в локал сторе
	localStore = null;

	limit = 10;
	tabs = [];

	constructor(props) {
		if (props.tabs) this.tabs = props.tabs.map((r, index) => ({ index: index, ...r }));
		if (props.record) this.record = props.record;
		if (props.getInstance) props.getInstance({ reload: this.reload });
		if (props.localStore) this.localStore = props.localStore;
		if (props.perPage) this.limit = props.perPage;
		if (this.tabs.length) {
			this.activeTab = this.tabs[0];
			this.fetchRecords();
		}
	}

	handlerUpdateMessages = () => {
		this.fetchRecords();
	};

	fetchRecords = async () => {
		let items;
		if (this.activeTab.type === 'comments') items = await this.fetchComment();
		if (this.activeTab.type === 'logs' && this.activeTab.model) items = await this.fetchLogsByModel();
		if (this.activeTab.type === 'logs' && !this.activeTab.model) items = await this.fetchLogs();
		if (this.activeTab.type === 'messages') items = await this.fetchMessages();
		this.items = this.page === 1 ? items : [...this.items, ...items];
		this.isLoading = false;
		this.isInit = true;
		this.isTabInit = true;
	};

	fetchMessages = async () =>
		this.record?.messages
			? await this.record.messages.find({
					/*limit: this.limit,
					skip: (this.page - 1) * this.limit,*/
					order: 'createdAt ASC',
					include: ['owner', 'attachments'],
				})
			: [];

	fetchLogs = async () =>
		this.record?.logs
			? await this.record.logs.find({
					...this.activeTab.filter,
					limit: this.limit,
					skip: (this.page - 1) * this.limit,
					order: this.order,
				})
			: [];

	fetchLogsByModel = async () =>
		await this.activeTab.model.find({
			...this.activeTab.filter,
			limit: this.limit,
			skip: (this.page - 1) * this.limit,
			order: this.order,
		});

	fetchComment = async () =>
		this.record?.comments
			? await this.record.comments.find({
					limit: this.limit,
					skip: (this.page - 1) * this.limit,
					order: this.order,
				})
			: [];

	onOrderChange = () => {
		this.order = this.order === 'id desc' ? 'id asc' : 'id desc';
		this.reload();
	};

	onTabChange = (e, index, tab) => {
		if (this.activeTab.index !== index) {
			this.activeTab = tab;
			this.isTabInit = false;
			this.reload();
		}
	};

	onShowPopup =
		(popupProps = { computedPropsFunctions: {}, baseModelRelationName: null, log: null }) =>
		async (field) => {
			const { computedPropsFunctions, baseModelRelationName, log, index } = popupProps;

			if (!log) return;

			const model = this.activeTab.type === 'logs' && this.activeTab.model ? this.activeTab.model : this.record.logs;

			const _logs = await model.find({
				where: {
					id: { lt: log.record.id },
					[field]: { neq: null },
				},
				limit: 1,
				order: 'createdAt desc',
			});

			this.popup = {
				log,
				logs: _logs,
				index: log.index,
				onClose: () => (this.popup = null),
				computedPropsFunctions,
				baseModelRelationName,
				field: field,
			};
		};

	reload = () => {
		this.page = 1;
		this.isLoading = true;
		this.fetchRecords();
	};
}
