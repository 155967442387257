import React from 'react';
import { inject } from 'mobx-react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

const cancel = (e) => e.preventDefault();

@inject('store')
@observer
export default class CardRecord extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	render() {
		const { renderItem, record } = this.props;
		const { handleDragStart, draggedItem } = this.store;

		return (
			<div
				draggable
				onClick={cancel}
				onDragStart={(e) => handleDragStart(e, record)}
				className={classNames({ 'draggable-active': draggedItem?.id === record.id }, 'cl-card-record')}
			>
				{renderItem(record)}
			</div>
		);
	}
}
