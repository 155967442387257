import Root from './Root';
import React from 'react';
import t from 'i18n';
import {
	STOREHOUSE_WAREHOUSES_PATH,
	STOREHOUSE_NOMENCLATURES_PATH,
	STOREHOUSE_NOMENCLATUREMOVEMENTS_PATH,
	STOREHOUSE_SETTINGS_PATH,
} from 'client/modules/constants';

import { faWarehouse, faBoxes, faBookOpen, faRuler, faArrowRightArrowLeft, faGear } from '@fortawesome/free-solid-svg-icons';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('storehouse.title'),

	/** Короткий заголовок модуля */
	titleShort: t('storehouse.title'),

	/** Код модуля на латинице, обязателен */
	code: 'storehouse',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда */
	// dashboardCard: StorehouseCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			path: STOREHOUSE_NOMENCLATURES_PATH,
			label: t('nomenclature.plural'),
			icon: faBoxes,
		},
		{
			type: 'item',
			path: STOREHOUSE_NOMENCLATUREMOVEMENTS_PATH,
			label: t('nomenclatureMovement.title'),
			icon: faArrowRightArrowLeft,
		},
		{
			type: 'item',
			path: STOREHOUSE_WAREHOUSES_PATH,
			label: t('productionOrder.warehouses'),
			icon: faWarehouse,
		},
		{
			type: 'item',
			path: STOREHOUSE_SETTINGS_PATH,
			label: t('settings'),
			icon: faGear,
		},
	],
};
