import React from 'react';
import { AutoAnimate } from '@smartplatform/ui';
import CellCalendar from 'components/calendar-records/CellCalendar';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import classNames from 'classnames';
import { t } from 'i18next';
import { formatDate, renderFaIcons } from 'client/tools';
import { HOURS } from 'components/calendar-records/constants';
import { getLabelParentByModel } from 'client/tools/getLabelParentByModel';

@inject('store')
@observer
export default class WeekDay extends React.Component {
	@observable showStartDay = false;
	@observable showEndDay = false;
	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.startDayHours = HOURS.filter((hour) => parseInt(hour) < 9);
		this.endDayHours = HOURS.filter((hour) => parseInt(hour) > 18);
		this.workingHours = HOURS.filter((hour) => parseInt(hour) >= 9 && parseInt(hour) <= 18);
	}

	renderHour = (props) => {
		const { day, hour, recordsByHour, collapsed, toggleProp } = props;
		const { handleDrop, handleDragOver } = this.store;
		const dateWithHour = new Date(day.fullDate).setHours(parseInt(hour.split(';')[0]), 0, 0, 0);

		return (
			<AutoAnimate
				className={classNames('content-hour-day-week', { collapsed: collapsed && !this[toggleProp] })}
				key={hour}
				onDrop={(e) => handleDrop(e)}
				onDragOver={(e) => handleDragOver(e, dateWithHour)}
			>
				<div className='wrapper-head-hour'>
					<p className='head-hour'>{hour}</p>
					<div className='line'></div>
					{collapsed && (
						<div className='toggle-icon' onClick={() => this.toggleFunc(toggleProp)}>
							{!this[toggleProp] ? renderFaIcons.plus : renderFaIcons.minus}
						</div>
					)}
				</div>
				{(!collapsed || this[toggleProp]) && (
					<CellCalendar day={{ ...day, fullDate: dateWithHour }} filteredRecords={recordsByHour} {...this.props} />
				)}
			</AutoAnimate>
		);
	};

	toggleFunc = (prop) => {
		this[prop] = !this[prop];
	};

	renderStartDayHour = (props) => {
		const { hour } = props;
		const collapsed = this.startDayHours.findIndex((h) => h === hour) === 0;
		if (!collapsed && !this.showStartDay) return null;
		return this.renderHour({ ...props, collapsed, toggleProp: 'showStartDay' });
	};

	renderEndDayHour = (props) => {
		const { hour } = props;
		const collapsed =
			this.endDayHours.findIndex((h) => h === hour) === 0 ||
			this.endDayHours.findIndex((h) => h === hour) === this.endDayHours.length - 1;
		if (!collapsed && !this.showEndDay) return null;
		return this.renderHour({ ...props, collapsed, toggleProp: 'showEndDay' });
	};

	render() {
		const { dateProperty, model, dayRecords, day } = this.props;
		const { renderHour, renderStartDayHour, renderEndDayHour, startDayHours, endDayHours, workingHours } = this;
		const modelTranslation = getLabelParentByModel(model.INFO.name);

		const countStartDayRecords = dayRecords.filter((r) => this.startDayHours.includes(formatDate(r[dateProperty], 'HH:mm'))).length;
		const countEndDayRecords = dayRecords.filter((r) => this.endDayHours.includes(formatDate(r[dateProperty], 'HH:mm'))).length;

		return (
			<div className={classNames({ 'day-week': true, active: day.active })}>
				<p className='head-day-week'>{day.name}</p>
				<div className='weekday-wrapper-top-content'>
					<p className='count'>{`${dayRecords.length} ${t(`${modelTranslation}.${modelTranslation}`, { count: dayRecords.length })}`}</p>
					<p className='head-day-date'>{day.numberDay}</p>
				</div>
				<div className='wrapper-hours-day'>
					<AutoAnimate className='hidden-records'>
						{startDayHours.map((hour) =>
							renderStartDayHour({
								day,
								hour,
								recordsByHour: dayRecords.filter((r) => formatDate(r[dateProperty], 'HH:mm') === hour),
							})
						)}
						{!!countStartDayRecords && <div className='count-hidden-records'>{countStartDayRecords}</div>}
					</AutoAnimate>
					{workingHours.map((hour) =>
						renderHour({
							day,
							hour,
							recordsByHour: dayRecords.filter((r) => formatDate(r[dateProperty], 'HH:mm') === hour),
						})
					)}
					<AutoAnimate className='hidden-records'>
						{endDayHours.map((hour) =>
							renderEndDayHour({
								day,
								hour,
								recordsByHour: dayRecords.filter((r) => formatDate(r[dateProperty], 'HH:mm') === hour),
							})
						)}
						{!!countEndDayRecords && <div className='count-hidden-records'>{countEndDayRecords}</div>}
					</AutoAnimate>
				</div>
			</div>
		);
	}
}
