import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Table, Column } from '@smartplatform/ui';
import store from 'client/store';

import './testTabel.scss';

@observer
export default class TableTest extends React.Component {
	@observable records = [];
	@observable types = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.records = await store.model.User.find();
	};

	onRowClick = async (record) => {
		this.records = this.records.filter((r) => r.MODEL.name === 'User');
		const projects = await record.projects();
		const index = this.records.findIndex((r) => r.id === record.id);
		this.records.splice(index, 0, projects);
	};

	getChildrenType = (type) => {
		this.types.push(type);
	};

	//* задумка для респонсив таблицы
	// getChildrenWidthColumn = (childrenIndex, width) => {
	// 	const widthArray = [
	// 		`20px`,
	// 		`minmax(min-content, 1fr)`,
	// 		`minmax(min-content, 1fr)`,
	// 		`minmax(min-content, 1fr)`,
	// 		`minmax(min-content, 1fr)`,
	// 	];

	// 	widthArray[childrenIndex] = `${width}px`;
	// };

	//!тейбл будет получать количество детей и это количество указывать в repeat

	render() {
		return (
			<div className='test-table'>
				<Table
					rows={this.records}
					// onRowClick={this.onRowClick}
				>
					<Column property='id' label='id' width={200} />
					<Column property='email' label='email' />
					<Column property='lastName' label='email' />
					<Column property='firstName' label='email' />
					<Column property='middleName' label='email' />
				</Table>
			</div>
		);
	}
}

