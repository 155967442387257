import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { RecordSelect, Select, ColorUtils } from '@smartplatform/ui';
import { User } from 'components';
import store from 'client/store';
import './style.scss';

const comparator = (a, b) => a && b && a.id === b.id;

const ITEMS = [
	{ label: 'one', value: { id: 1, name: 'one' }},
	{ label: 'two', value: { id: 2, name: 'two' }},
	{ label: 'three', value: { id: 3, name: 'three' }},
	{ label: 'four', value: { id: 4, name: 'four' }},
	{ label: 'five', value: { id: 5, name: 'five' }},
];

@observer
export default class RecordSelectTest extends React.Component {

	@observable selected = [];
	@observable selected2 = [];
	@observable search = '';
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		// const users = await store.model.User.find();
		// this.selected = users.sort(() => 0.5 - Math.random()).slice(0, 3);
	};

	onChange = (selected) => {
		this.selected = selected;
	};

	onChange2 = (selected) => {
		this.selected2 = selected;
	};

	removeItem = (item) => (e) => {
		e.stopPropagation(); // прерываем передачу события onClick, чтобы он не открывался DropDown
		const index = this.selected.findIndex(a => comparator(a, item));
		if (index !== -1) this.selected.splice(index, 1);
	};

	renderUser = (user) => <User user={user} />;

	onSearch = (str) => {
		console.log('onSearch', str)
		this.search = str;
	};

	renderItemTag = (item) => {
		const { background, color } = ColorUtils.getColorForString(item.email);
		const style = { color, background: ColorUtils.brighten(background, 30) };
		return <div className="user-tag" style={style}>
			<User user={item} size={16} />
			<div className="remove-btn" onClick={this.removeItem(item)}><FontAwesomeIcon icon={faTimes} /></div>
		</div>;
	};

	render() {
		return <div>
			<RecordSelect
				model={store.model.User}
				value={this.selected}
				onChange={this.onChange}
				searchPlaceholder="Поиск по email"
				comparator={comparator}
				computed={this.renderUser}
				renderItemTag={this.renderItemTag}
				onSearch={this.onSearch}
				filter={{ search: this.search }}
				width={350}
				sortSelected
				showSelectControls // пока не работает
				showValue="Выберите пользователей"
			/>
			<Select
				items={ITEMS}
				value={this.selected2}
				onChange={this.onChange2}
				comparator={comparator}
				sortSelected
				showSelectControls // пока не работает
				noSearch
			/>
		</div>;
	}

}
