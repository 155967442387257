import React from 'react';
import { observer, inject } from 'mobx-react';
import { NumberInput, DatePicker } from '@smartplatform/ui';
import { Mode, Toolbar } from 'components';
import { renderList } from 'client/tools';
import { PAID_SELECT_ITEMS } from '../constants';
import { renderPlaceholder } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { MODES } from 'client/constants';
import './orderToolbar.scss';

@inject('store')
@observer
export class OrdersToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { isReportMode, noButton } = this.props;
		const {
			isManagerOrAdmin,
			availableListIds,
			mode,
			onModeChange,
			onChange,
			search,
			onSearch,
			onDatechange,
			orderList,
			cleanFilters,
			managers,
			orderFilters,
			onChangeOrderFilters,
			ordersCount,
		} = this.store;

		const modes = (
			<Mode mode={mode} onChange={onModeChange}>
				<Mode.Tiles />
				<Mode.Kanban />
				<Mode.Calendar />
				<Mode.Report />
			</Mode>
		);

		if (mode === MODES.REPORT) {
			return modes;
		}

		const selectProps = { noTotal: true, maxItems: 10, width: 150 };

		const listSelectShowValue = orderList.length > 0 ? `${t(`selected`)}: ${orderList.length}` : renderPlaceholder(t('order.status'));

		const isButtonVisible = !noButton;

		return (
			<Toolbar className='orders-toolbar'>
				<Toolbar.Top>
					<Toolbar.LeftSide>
						{isButtonVisible && (
							<Toolbar.Bottom>
								<Toolbar.CreateBtn onClick={() => store.route.push({ path: 'orders/create' })} text={t('order.new')} />
								<Toolbar.Count count={ordersCount} text={t('order.totalOrders')} />
								<Toolbar.AvatarGroup
									avatarsCount={15}
									size={34}
									items={managers}
									onItemClick={onChangeOrderFilters('manager', true)}
									selectedId={orderFilters.manager}
									badgeKey={'ordersCount'}
									title={t('order.managers')}
								/>
							</Toolbar.Bottom>
						)}
					</Toolbar.LeftSide>
					<Toolbar.RightSide>{!isReportMode && modes}</Toolbar.RightSide>
				</Toolbar.Top>
				<div className='middle'>
					<Toolbar.Search placeholder={t('order.searchHint')} value={search} onSearch={onSearch} />
					{isManagerOrAdmin && (
						<>
							{['priceFrom', 'priceTo'].map((priceValue) => (
								<NumberInput
									key={priceValue}
									positiveOnly
									value={this.store[priceValue]}
									onChange={onChange(priceValue)}
									className='price'
									placeholder={t(`order.${priceValue}`)}
								/>
							))}
						</>
					)}
					<Toolbar.ListSelect
						model={store.model.OrderList}
						value={orderList}
						onChange={onChange('orderList')}
						computed={renderList}
						filter={{ where: availableListIds.length ? { id: { inq: availableListIds } } : undefined }}
						placeholder={t('order.status')}
						searchPlaceholder={t('search')}
						showValue={listSelectShowValue}
						{...selectProps}
					/>
					<Toolbar.ItemsSelect
						items={PAID_SELECT_ITEMS}
						value={this.store.paid}
						onChange={onChange('paid')}
						placeholder={t('order.paidStatus')}
						noSearch
						{...selectProps}
					/>
					<DatePicker
						value={this.store.startDateFrom}
						onChange={onDatechange('startDateFrom', true)}
						showTimeSelect={false}
						format='dd.MM.yyyy'
						placeholder={t('order.startDateFrom')}
					/>
					<DatePicker
						value={this.store.startDateTo}
						onChange={onDatechange('startDateTo', true)}
						showTimeSelect={false}
						format='dd.MM.yyyy'
						placeholder={t('order.startDateTo')}
					/>
					<Toolbar.CleanButton onClick={cleanFilters} />
				</div>
			</Toolbar>
		);
	}
}

