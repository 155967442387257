import React from 'react';
import classNames from 'classnames';
import { Popup, Button } from '@smartplatform/ui';
import store from 'client/store';

import PropTypes from 'prop-types';
import t from 'i18n';
import './confirmPopup.scss';

export class ConfirmPopup extends React.Component {
	static propTypes = {
		onClose: PropTypes.func,
		onConfirm: PropTypes.func,
		onCancel: PropTypes.func,
		message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	};
	static defaultProps = {
		message: t('confirm'),
		buttonVariant: 'primary',
		cancelText: t('cancel'),
		buttonText: t('confirm'),
		title: t('confirm'),
	};

	render() {
		const { onClose, onConfirm, title, description, buttonText, buttonVariant, onCancel, cancelText } = this.props;
		return (
			<Popup onClose={onClose} className='confirm-popup'>
				<div className='title'>{title}</div>
				{description && <div className='description'>{description}</div>}
				<div className='buttons'>
					<Button variant={buttonVariant} onClick={onConfirm}>
						{buttonText}
					</Button>
					{onCancel && (
						<Button variant='default' onClick={onCancel}>
							{cancelText}
						</Button>
					)}
				</div>
			</Popup>
		);
	}
}

