import React from 'react';
import { observer } from 'mobx-react';
import { DueDate, TruncateText } from 'components';
import { NumberValue, Avatar, Popover } from '@smartplatform/ui';
import { fio, relative, renderFaIcons } from 'client/tools';
import t from 'i18n';

@observer
export default class OrderCard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { order, list, store } = this.props;
		const { isManagerOrAdmin } = store;

		const positions = typeof order.positions === 'function' ? order.positions() : [];
		const _positions = positions.then ? [] : positions;

		return (
			<>
				<div className='order-card-top'>
					<div className='id'># {order.id}</div>
					<div className='updated'>
						{order.comment ? (
							<Popover
								content={order.comment}
								offset={0}
								className='test-1'
								contentClassName='popover-order-comment'
								trigger='hover'
								position='bottom'
							>
								{renderFaIcons.comment}
							</Popover>
						) : (
							''
						)}
						{order.dueDate && !order.closed ? <DueDate date={order.dueDate} relative /> : relative(order.updatedAt)}
					</div>
				</div>
				<div className='order-card-main'>
					{order.manager && (
						<Popover content={fio(order.manager)} trigger='hover' position='bottom'>
							<Avatar className='manager' user={order.manager} />
						</Popover>
					)}
					{order.description && <div className='name'>{order.description}</div>}
					<div className='client'>
						{t('client.title')}: <em>{order.contragentName || order.clientName || '-'}</em>
					</div>
					<div className='paidStatus'>
						{t('order.paidStatus')}: <em>{order.paid ? 'оплачен' : 'не оплачен'}</em>
					</div>
					{_positions.length > 0 && (
						<div className='positions'>
							{_positions.slice(0, 2).map((position, i) => (
								<div key={position.id} className='position'>
									<TruncateText className='category'>
										{i + 1}. {position.category?.name || position.nomenclature?.name}
									</TruncateText>
									<div className='amount'>{position.amount} шт.</div>
									{isManagerOrAdmin && (
										<div className='price'>
											<NumberValue value={position.price} type='currency' />
										</div>
									)}
								</div>
							))}
							{_positions.length > 2 && <div>...</div>}
						</div>
					)}
					{_positions.length > 0 && (
						<div className='total'>
							{isManagerOrAdmin && (
								<div className='price'>
									{t('order.totalShort')}:{' '}
									<em>
										<NumberValue value={order.total} type='currency' />
									</em>
								</div>
							)}
							<div className='positions-count'>
								{t('order.positionsCount')}: {_positions.length}
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
}

