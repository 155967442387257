import React from 'react';
import './style.scss';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { renderList, renderPlaceholder } from 'client/tools';
import { Mode, Toolbar } from 'components';
import store from 'client/store';
import MobileFilter from 'components/mobile-filter/MobileFilter';
import { DatePicker, NumberInput, RecordSelect, Row } from '@smartplatform/ui';
import { PAID_SELECT_ITEMS } from 'client/modules/sales/orders/constants';

@inject('store')
@observer
export default class MobileToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { isReportMode, noButton } = this.props;
		const {
			isManagerOrAdmin,
			availableListIds,
			mode,
			onModeChange,
			onChange,
			search,
			onSearch,
			onDatechange,
			orderList,
			cleanFilters,
			compileFilters,
		} = this.store;

		const selectProps = { noTotal: true, maxItems: 10, width: 150 };
		const listSelectShowValue = orderList.length > 0 ? `${t(`selected`)}: ${orderList.length}` : renderPlaceholder(t('order.status'));
		const isButtonVisible = !noButton;

		return (
			<Toolbar className='mobile-toolbar-orders'>
				<Toolbar.Top>
					<Toolbar.Search placeholder={t('order.searchHint')} value={search} onSearch={onSearch} />
				</Toolbar.Top>
				<div className='middle'>
					{isButtonVisible && (
						<Toolbar.Bottom>
							<Toolbar.CreateBtn onClick={() => store.route.push({ path: 'orders/create' })} text={t('order.new')} />
						</Toolbar.Bottom>
					)}
					<MobileFilter applyFilter={compileFilters} resetFilter={cleanFilters}>
						<Row>
							{isManagerOrAdmin &&
								['priceFrom', 'priceTo'].map((priceValue) => (
									<NumberInput
										key={priceValue}
										positiveOnly
										value={this.store[priceValue]}
										onChange={onChange(priceValue, false)}
										className='price'
										placeholder={t(`order.${priceValue}`)}
									/>
								))}
						</Row>
						<Toolbar.ListSelect
							model={store.model.OrderList}
							value={orderList}
							onChange={onChange('orderList', false)}
							computed={renderList}
							filter={{ where: availableListIds.length ? { id: { inq: availableListIds } } : undefined }}
							placeholder={t('order.status')}
							searchPlaceholder={t('search')}
							showValue={listSelectShowValue}
							{...selectProps}
						/>
						<Toolbar.ItemsSelect
							items={PAID_SELECT_ITEMS}
							value={this.store.paid}
							onChange={onChange('paid', false)}
							placeholder={t('order.paidStatus')}
							noSearch
							{...selectProps}
						/>
						<RecordSelect
							model={store.model.PriceCategory}
							property='name'
							value={this.store.category}
							onChange={onChange('category', false)}
							placeholder={t('order.category')}
							searchPlaceholder={t('search')}
							searchFields={['name']}
							isRequired={false}
							{...selectProps}
						/>
						<Row>
							<DatePicker
								value={this.store.startDateFrom}
								onChange={onDatechange('startDateFrom', true, false)}
								showTimeSelect={false}
								format='dd.MM.yyyy'
								placeholder={t('order.startDateFrom')}
							/>
							<DatePicker
								value={this.store.startDateTo}
								onChange={onDatechange('startDateTo', true, false)}
								showTimeSelect={false}
								format='dd.MM.yyyy'
								placeholder={t('order.startDateTo')}
							/>
						</Row>
						<Row>
							<DatePicker
								value={this.store.endDateFrom}
								onChange={onDatechange('endDateFrom', false, false)}
								showTimeSelect={false}
								format='dd.MM.yyyy'
								placeholder={t('order.endDateFrom')}
							/>
							<DatePicker
								value={this.store.endDateTo}
								onChange={onDatechange('endDateTo', false, false)}
								showTimeSelect={false}
								format='dd.MM.yyyy'
								placeholder={t('order.endDateTo')}
							/>
						</Row>
					</MobileFilter>
				</div>
				<Toolbar.Bottom>
					{!isReportMode && (
						<Mode mode={mode} onChange={onModeChange}>
							<Mode.Tiles />
							{/* <Mode.List /> */}
							<Mode.Kanban />
							<Mode.Calendar />
						</Mode>
					)}
				</Toolbar.Bottom>
			</Toolbar>
		);
	}
}

