import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faExclamationTriangle, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from 'components';
import classNames from 'classnames';
import { Loader, FormContext, Button } from '@smartplatform/ui';
import Attachment from './Attachment';

import { formatDate } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './attachments.scss';

const bytesToSize = (bytes) => {
	var sizes = [t('bytes'), t('Kb'), t('Mb'), t('Gb'), t('Tb')];
	if (bytes === 0) return '0 ' + sizes[0];
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

@observer
export class Attachments extends React.Component {
	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		property: PropTypes.string,
		canUpload: PropTypes.bool,
		canDelete: PropTypes.bool,
		uploadIsImage: PropTypes.bool,
		onChange: PropTypes.func,
		onDelete: PropTypes.func,
		softDelete: PropTypes.bool,
		withFormContext: PropTypes.bool,
		isDate: PropTypes.bool,
		archiveAttachments: PropTypes.bool,
	};

	static contextType = FormContext;

	static defaultProps = {
		relation: 'attachments',
		property: 'filename',
		canUpload: true,
		canDelete: true,
		softDelete: false,
		withFormContext: false,
		isDate: false,
		archiveAttachments: false,
	};

	@observable attachments = [];
	@observable newFiles = [];
	@observable errors = [];
	@observable isUploading = false;
	@observable preparationArchive = false;
	@observable percentProgressCompression = 0;

	constructor(props) {
		super(props);
		this.record = this.props.record;
		this.id = this.props.record.MODEL.name + '-' + this.props.record.id;
		const modelName = this.props.record.MODEL.RELATIONS[this.props.relation].model;
		this.model = store.model[modelName];
		this.init();
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.attachments && this.props.attachments.length !== prevProps.attachments.length) {
			this.init();
		}
	};

	init = async () => {
		if (this.props.attachments) {
			this.attachments = this.props.attachments;
		} else if (this.record.id) {
			this.attachments = await this.record[this.props.relation].find({ include: ['owner'], order: 'id asc' });
		}
	};

	onAttach = async (e) => {
		const newFiles = [];
		this.errors = [];
		for (let file of e.target.files) {
			const attachment = new this.model();
			attachment[this.props.property] = file.name;
			newFiles.push({
				attachment,
				file,
				uploadProgress: 0,
				done: false,
				error: null,
			});
		}
		this.newFiles = newFiles;
		this.isUploading = true;
		const promises = this.newFiles.map(this.upload);
		await Promise.all(promises);

		this.isUploading = false;
		this.errors = this.newFiles.filter((uploadObj) => !!uploadObj.error);
		if (!this.errors.length && this.props.onChange) this.props.onChange(this.newFiles);
		this.newFiles = [];
	};

	handleFileProgress = (uploadObj, event) => {
		uploadObj.uploadProgress = event.percent;
	};

	upload = async (uploadObj) => {
		await uploadObj.attachment.save();
		await uploadObj.attachment.owner;
		try {
			const res = await this.uploadFile(uploadObj);
			if (this.props.withFormContext) {
				this.context.form.addManyToMany(this.props.relation, uploadObj.attachment.id);
			} else {
				await this.props.record[this.props.relation].add(uploadObj.attachment.id);
			}

			uploadObj.done = true;
			this.attachments.push(uploadObj.attachment);
			this.props.archiveAttachments && (await this.record.reload());
		} catch (error) {
			if (error.status === 413) {
				uploadObj.error = t('file.fileTooBig');
			} else {
				uploadObj.error = t('file.uploadError');
			}
			await uploadObj.attachment.delete();
		}
	};

	uploadFile = (uploadObj) =>
		new Promise((resolve, reject) => {
			uploadObj.attachment
				.uploadFile(this.props.property, uploadObj.file)
				.on('progress', (event) => this.handleFileProgress(uploadObj, event))
				.end((error, result) => {
					console.error({ error, result });
					if (!error) {
						resolve(result);
					} else {
						reject(error);
					}
				});
		});

	deleteAttach = async ({ attachment, property }) => {
		const index = this.attachments.findIndex((_attachment) => _attachment.id === attachment.id);
		if (index !== -1) this.attachments.splice(index, 1);
		if (this.props.withFormContext) this.context.form.removeManyToMany(this.props.relation, attachment.id);
		else {
			await this.props.record[this.props.relation].remove(attachment.id);
			if (!this.props.softDelete) {
				await attachment.deleteFile(property);
				await attachment.delete();
			}
		}
		if (this.props.onDelete) this.props.onDelete();

		this.props.archiveAttachments && (await this.record.reload());
	};

	handlerProgressCompression = (data) => {
		this.percentProgressCompression = parseInt((data.processedBytes / data.totalBytes) * 100);
	};

	downloadArchiveAttachments = async () => {
		if (!this.record.archiveAttachments) {
			store.socket.on('Record.progressCompression', this.handlerProgressCompression);
			this.preparationArchive = true;
			const resultCreate = await this.record.createArchiveAttachments();
			this.preparationArchive = false;
			if (resultCreate) {
				store.socket.removeAllListeners('Record.progressCompression');
				this.percentProgressCompression = 0;
				location.href = this.record.downloadFile('archiveAttachments');
			}
		} else {
			location.href = this.record.downloadFile('archiveAttachments');
		}
		await this.record.reload();
	};

	render() {
		const { RenderCustomAttachment, renderCustomAttachmentButton, isDate } = this.props;

		const button = renderCustomAttachmentButton || (
			<IconButton icon={<FontAwesomeIcon icon={faPaperclip} />} endText={t('toAttachFile')} noBorder />
		);

		return (
			<>
				<div
					className={classNames('attachments', {
						'upload-image': this.props.uploadIsImage,
						loading: this.isUploading,
					})}
				>
					{this.props.canUpload && (
						<div className='upload'>
							<input type='file' id={this.id} onChange={this.onAttach} multiple />
							<label htmlFor={this.id}>{button}</label>
						</div>
					)}
					{this.props.archiveAttachments && this.attachments.length > 0 && this.props.record.id && (
						<Button className='download-all' onClick={this.downloadArchiveAttachments}>
							<FontAwesomeIcon icon={faDownload} />
							{this.preparationArchive ? (
								<div className='process-archive-attachments'>
									<span>{t('archiveAttachments.preparation')}</span>
									<span>{this.percentProgressCompression}%</span>
									<Loader size={20} />
								</div>
							) : (
								t('archiveAttachments.download')
							)}
						</Button>
					)}
				</div>
				<div className='errors-and-list'>
					{this.isUploading && (
						<div className='new-files'>
							{this.newFiles.map((uploadObj, i) => {
								return uploadObj.done && !uploadObj.error ? (
									<Attachment key={uploadObj.attachment.id} attachment={uploadObj.attachment} isNew />
								) : (
									<div key={i} className='new-file'>
										<div className='icon'>
											<Loader size={14} />
										</div>
										<div className='info'>
											<div className='file-name'>{uploadObj.file.name}</div>
											{uploadObj.uploadProgress !== undefined && (
												<div className='progress'>
													{t('file.uploading')}: <em>{Math.round(uploadObj.uploadProgress) + '%'}</em> из
													<em>{bytesToSize(uploadObj.file.size)}</em>
												</div>
											)}
										</div>
									</div>
								);
							})}
						</div>
					)}
					{this.errors.length > 0 && (
						<div className='upload-errors'>
							{this.errors.map((uploadObj, i) => {
								return (
									<div key={i} className='new-file upload-error'>
										<div className='icon'>
											<FontAwesomeIcon icon={faExclamationTriangle} />
										</div>
										<div className='info'>
											<div className='file-name'>{uploadObj.file.name}</div>
											<div className='error-msg'>{uploadObj.error}</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
					{this.attachments.length > 0 && (
						<div className='list'>
							{this.attachments.map((attachment) =>
								RenderCustomAttachment ? (
									<RenderCustomAttachment key={attachment.id} attachment={attachment} deleteAttach={this.deleteAttach} />
								) : (
									<React.Fragment key={attachment.id}>
										<Attachment
											canDelete={this.props.canDelete}
											key={attachment.id}
											attachment={attachment}
											deleteAttach={this.deleteAttach}
											softDelete={this.props.softDelete}
										/>
										{isDate && <div>{formatDate(attachment.createdAt)}</div>}
									</React.Fragment>
								)
							)}
						</div>
					)}
				</div>
			</>
		);
	}
}
