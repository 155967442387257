import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { inject, observer } from 'mobx-react';
import { Toolbar as _Toolbar } from 'components';

const CLOSED_BUTTONS_ITEMS = [
	{ name: 'week', value: 'week', label: t('week') },
	{ name: 'month', value: 'month', label: t('month') },
];

@inject('store')
@observer
export default class Toolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	onChangeMode = (value) => {
		if (this.store.onChange) this.store.onChange('mode', value);
		this.store.getDaysCalendar();
		if (this.props.updateRecords) this.props.updateRecords();
	};

	render() {
		const { mode, onPrev, onNext, valueSwitcher } = this.store;
		const { updateRecords } = this.props;
		return (
			<_Toolbar className='calendar-toolbar'>
				<_Toolbar.Top>
					<_Toolbar.ButtonGroup
						buttons={CLOSED_BUTTONS_ITEMS}
						buttonOnClick={(value) => this.onChangeMode(value)}
						closed={mode}
					/>
					<_Toolbar.PrevNextSwitcher
						onPrev={() => onPrev(updateRecords)}
						onNext={() => onNext(updateRecords)}
						value={valueSwitcher}
					/>
				</_Toolbar.Top>
			</_Toolbar>
		);
	}
}
