import React from 'react';
import store from 'client/store';

export const withTitleAndLink = (WrappedComponent, options) => {
	return class extends React.Component {
		componentDidMount = () => {
			if (options?.title) store.ui.title = options?.title;
			if (options?.backRoute) {
				store.ui.backRoute = options?.backRoute;
			}
		};
		componentWillUnmount = () => {
			store.ui.title = '';
			store.ui.backRoute = null;
		};
		render = () => <WrappedComponent {...this.props} {...options?.componentProps} />;
	};
};
