import React from 'react';
import { inject, observer } from 'mobx-react';
import { formatDate } from 'client/tools';
import WeekDay from 'components/calendar-records/WeekDay';

@inject('store')
@observer
export default class WeekMode extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.store;
	}
	render() {
		const { daysCalendar } = this.store;
		const { dateProperty, records } = this.props;

		return (
			<div className='week-calendar'>
				{daysCalendar.map((day, index) => {
					const dayRecords = records.filter(
						(record) => formatDate(record[dateProperty], 'dd.MM.yyyy') === formatDate(day.fullDate, 'dd.MM.yyyy')
					);
					return <WeekDay key={index} dayRecords={dayRecords} day={day} {...this.props} />;
				})}
			</div>
		);
	}
}
