import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, ButtonGroup } from '@smartplatform/ui';
import {
	Button as Button_,
	CleanButton,
	ExportButton,
	ImportButton,
	ChartButton,
	EditIconButton,
	AttachButton,
	AddUserButton,
	CancelIconButton,
	RefreshButton,
	DeleteButton,
	DeleteIconButton,
	IconButton,
} from 'components';
import { renderFaIcons } from 'client/tools';

@observer
export default class ButtonTest extends React.Component {
	@observable group = 1;

	render() {
		return (
			<>
				<div className='block'>
					<Button>Default</Button>
					<Button variant='primary'>Primary</Button>
					<Button variant='success'>Success</Button>
					<Button variant='danger'>Danger</Button>
					<Button variant='warning'>Warning</Button>
				</div>
				<div className='block'>
					<Button size='sm'>Small</Button>
					<Button>Default</Button>
					<Button size='lg'>Large</Button>
				</div>

				<div className='block'>
					<ButtonGroup>
						<Button onClick={() => (this.group = 1)} variant={this.group === 1 ? 'info' : 'primary'}>
							One
						</Button>
						<Button onClick={() => (this.group = 2)} variant={this.group === 2 ? 'info' : 'primary'}>
							Two
						</Button>
						<Button onClick={() => (this.group = 3)} variant={this.group === 3 ? 'info' : 'primary'}>
							Three
						</Button>
					</ButtonGroup>
				</div>
				<h2>Кнопки из компонентов</h2>
				<div className='block'>
					<Button_ centerRipple text={'qwqwqw'} />
					<Button_ variant='default' text={'qwqwqw'} />
					<Button_ centerRipple variant='default' text={'qwqwqw'} />
					<Button_ centerRipple variant='danger' text={'qwqwqw'} />
					<Button_ variant='success' text={'qwqwqw'} />
					<Button_ text={'qwqwqw'} />
				</div>
				<div className='block' style={{ display: 'flex', gap: 20 }}>
					<CleanButton />
					<ExportButton disabled />
					<ImportButton />
					<ChartButton />
					<EditIconButton />
					<AttachButton disabled />
					<AddUserButton noBorder />
					<CancelIconButton noBorder />
					<RefreshButton />
					<DeleteButton noBorder />
					<DeleteIconButton noBorder />
					<IconButton icon={renderFaIcons.memberIcon} size='sm' />
				</div>
			</>
		);
	}
}

