import React from 'react';
import t from 'i18n';
import { Avatar, Popover } from '@smartplatform/ui';
import { fio } from 'client/tools';
import format from 'date-fns/format';
import store from 'client/store';
import { OnlineBadge } from 'components';

export const ORDERS_MODE_REPORT = 'report';

export const PAID_SELECT_ITEMS = [
	{ label: t('order.paid'), value: true },
	{ label: t('order.notPaid'), value: 'false' },
];

export const LOCAL_STORAGE_FIELDS = [
	'mode',
	'hiddenLists',
	'search',
	'orderList',
	'order',
	'page',
	'priceTo',
	'priceFrom',
	'paid',
	'startDateFrom',
	'startDateTo',
	'calendarMode',
	'calendarCurrentDate',
];

//*КАНБАН
export const ORDER_KANBAN_INCLUDE = [
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{
		relation: 'manager',
		scope: {
			fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'],
			include: [{ relation: 'orders', scope: { fields: ['id', 'description'] } }],
		},
	},
	{
		relation: 'positions',
		scope: {
			where: {
				or: [{ categoryId: { neq: null } }, { nomenclatureId: { neq: null } }],
			},
			include: [
				{ relation: 'category', scope: { fields: ['id', 'name'] } },
				{ relation: 'nomenclature', scope: { fields: ['id', 'name'] } },
			],
			order: 'id asc',
		},
	},
	{
		relation: 'labels',
		scope: {
			fields: ['id', 'name', 'color'],
		},
	},
];
export const KANBAN_INITIAL_LIMIT = 20;

//*ЛИСТ
export const ORDER_LIST_INCLUDE = [
	{
		relation: 'list',
		scope: { fields: ['id', 'color', 'name'] },
	},
	...ORDER_KANBAN_INCLUDE,
];

//*ПЛИТКА
export const ORDER_TILES_INCLUDE = ORDER_LIST_INCLUDE;
export const ORDER_TILES_SORTING_VARIANTS = [
	{ label: <>&uarr; {t('sorting.newFirst')}</>, value: 'id desc' },
	{ label: <>&darr; {t('sorting.oldFirst')}</>, value: 'id asc' },
	{ label: <>&darr; {t('sorting.priceAsc')}</>, value: 'total asc' },
	{ label: <>&uarr; {t('sorting.priceDesc')}</>, value: 'total desc' },
	{ label: <>&uarr; {t('order.startDate')}</>, value: 'startDate desc' },
	{ label: <>&darr; {t('order.startDate')}</>, value: 'startDate asc' },
	{ label: <>&uarr; {t('order.endDate')}</>, value: 'endDate desc' },
	{ label: <>&darr; {t('order.endDate')}</>, value: 'endDate asc' },
];
export const ORDER_KANBAN_SORTING_VARIANTS = [
	{ label: <>&uarr; {t('sorting.newFirst')}</>, value: 'id desc' },
	{ label: <>&darr; {t('sorting.oldFirst')}</>, value: 'id asc' },
	{ label: <>&uarr; {t('sorting.priceAsc')}</>, value: 'total desc' },
	{ label: <>&darr; {t('sorting.priceDesc')}</>, value: 'total asc' },
	{ label: <>&uarr; {t('order.startDate')}</>, value: 'startDate desc' },
	{ label: <>&darr; {t('order.startDate')}</>, value: 'startDate asc' },
	{ label: <>&uarr; {t('order.endDate')}</>, value: 'endDate desc' },
	{ label: <>&darr; {t('order.endDate')}</>, value: 'endDate asc' },
];

export const PER_PAGE = 20;

export const DEFAULT_PRIORITY = 10000;

// Перенесено с отчетов для списка
const renderFullNameOrder = (order) => {
	return order?.description ? (
		<Popover content={order?.description} trigger='hover' className='popover-table'>
			{order?.description}
		</Popover>
	) : (
		'-'
	);
};

const renderPopoverFullNameContragent = (order) => {
	return order.contragentName ? (
		<Popover content={order.contragentName} trigger='hover' className='popover-table'>
			{order.contragentName}
		</Popover>
	) : (
		'-'
	);
};

const renderManagerAvatar = (order) => {
	return order.manager ? (
		<Popover content={fio(order.manager)} trigger='hover'>
			<OnlineBadge isOnline={store.onlineUsersMap.has(order.manager.id)}>
				<Avatar className='manager' user={order.manager} />
			</OnlineBadge>
		</Popover>
	) : (
		'-'
	);
};

const renderCategoriesAndAmount = (order) => {
	let string = '-';
	if (order.categoriesAndAmount) {
		string = order.categoriesAndAmount.split(' | ').map((item, index) => <span key={index}>{item}</span>);
	}

	return <div className='order-positions'>{string}</div>;
};

const renderDate = (property) => (order) => {
	return order[property] && format(new Date(order[property]), 'dd.MM.yyyy');
};

const _COLUMNS = [
	{ property: 'id', label: t('order.n'), width: 40 },
	{ property: 'description', label: t('order.title'), computed: renderFullNameOrder },
	{ property: 'status', label: t('order.status') },
	{ property: 'contragentName', label: t('order.client'), computed: renderPopoverFullNameContragent },
	{ property: 'total', label: t('order.total'), width: 80 },
	{ property: 'paid', label: t('order.paid'), width: 80 },
	{ property: 'fio', label: t('order.manager'), computed: renderManagerAvatar },
	{
		property: 'categoriesAndAmount',
		label: t('order.categoriesAndAmount'),
		computed: renderCategoriesAndAmount,
		width: 250,
	},
	{ property: 'dueDate', label: t('order.dueDate'), computed: renderDate('startDate') },
	{ property: 'startDate', label: t('order.startDate'), computed: renderDate('startDate') },
];
Object.keys(_COLUMNS).forEach((key) => (_COLUMNS[key].isSelected = true));
export const COLUMNS = _COLUMNS;

export const COUNT_ITEMS_LIST = [
	{ value: 100000, label: t('all') },
	{ value: 50, label: 50, default: true },
	{ value: 100, label: 100 },
	{ value: 500, label: 500 },
	{ value: 1000, label: 1000 },
];

