import React from 'react';
import { observer } from 'mobx-react';
import { Avatar, Popup } from '@smartplatform/ui';
import { formatDate, fioShort } from 'client/tools';
import t from 'i18n';
import './style.scss';

@observer
export default class HistoryDeltaPopup extends React.Component {
	renderValue = (field, record) => {
		let value;
		if (record) {
			const { computedPropsFunctions } = this.props;
			const nameRelation = Object.values(record.MODEL.RELATIONS).find((relation) => relation.foreignKey === field)?.name;
			if (nameRelation) {
				value = Object.keys(computedPropsFunctions || {})?.some((i) => i === field)
					? computedPropsFunctions[field](record)
					: record[nameRelation]?.name;
			} else {
				value = Object.keys(computedPropsFunctions || {})?.some((i) => i === field)
					? computedPropsFunctions[field](record)
					: record[field];
			}
		}
		return value || '-';
	};

	render() {
		const { onClose, log, logs, baseModelRelationName, field } = this.props;
		const { record } = log;
		const { renderValue } = this;
		const baseModelRelationKey = record.MODEL.RELATIONS?.[baseModelRelationName].foreignKey;

		const { recordLogs, index } = logs.reduce(
			(acc, value) => {
				if (value[baseModelRelationKey] === record[baseModelRelationKey]) acc.recordLogs.push(value);
				if (!acc.index && value.id === record.id) acc.index = acc.recordLogs.length - 1;
				return acc;
			},
			{ recordLogs: [] }
		);
		const _fields = field ? [field] : record.updated;

		return (
			<Popup onClose={onClose} className='history-delta-popup'>
				<div className='owner'>
					<Avatar user={record.owner} size={24} /> {fioShort(record.owner)}
					<span className='date'>{formatDate(record.createdAt, 'dd.MM.yyyy HH:mm')}</span>
				</div>

				<table>
					<thead>
						<tr>
							<th width='50%'>{t('history.deltaPopup.old')}</th>
							<th width='50%'>{t('history.deltaPopup.new')}</th>
						</tr>
					</thead>
					<tbody>
						{_fields.map((updatedField, i) => (
							<React.Fragment key={i}>
								<tr>
									<td colSpan={2}>{t(`${baseModelRelationName}.` + updatedField)}</td>
								</tr>
								<tr>
									<td>
										{renderValue(
											updatedField,
											recordLogs
												.slice(0, index)
												.findLast((record) => record[updatedField] !== undefined && record[updatedField] !== null)
										)}
									</td>
									<td>{renderValue(updatedField, record)}</td>
								</tr>
							</React.Fragment>
						))}
					</tbody>
				</table>
			</Popup>
		);
	}
}
