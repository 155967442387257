import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { Section } from 'components';
import './style.scss';
import classNames from 'classnames';
import { Tab, Tabs } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import HistoryTabContent from 'components/history-tab/HistoryTabContent';
import HistoryTabsStore from 'components/history-tab/store';

@observer
export default class HistoryTabs extends React.Component {
	constructor(props) {
		super(props);
		this.store = new HistoryTabsStore(props);
	}

	render() {
		const { tabs, onTabChange, onOrderChange, order, activeTab } = this.store;

		if (!tabs.length) return null;

		return (
			<Section
				className='main-history-wrapper'
				title={
					<div className='title'>
						<span>{t('history.sectionTitle')}</span>
						{activeTab.type !== 'messages' && <SortIcon trigger={order === 'id asc'} onClick={onOrderChange} />}
					</div>
				}
			>
				<Tabs className='history-nav' type='manual'>
					{tabs.map((tabInfo, i) => (
						<Tab key={i} {...tabInfo} onClick={onTabChange} />
					))}
				</Tabs>
				<HistoryTabContent store={this.store} />
			</Section>
		);
	}
}

const SortIcon = ({ trigger, ...restProps }) => (
	<div className={classNames('icon-sort', { sorted: trigger })} {...restProps}>
		<div className='top' />
		<div className='medium' />
		<div className='bot' />
	</div>
);
