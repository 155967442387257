import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import VariantsList from './NomenclaturesList';
import { FeatureSelector } from '../feature-selector';
import { Section, Button, ApproveIconButton } from 'components';
import store from 'client/store';
import './style.scss';
import t from 'i18n';
import { renderFaIcons } from 'client/tools';
import { Input, Popup, RecordSelect } from '@smartplatform/ui';

@inject('productStore')
@observer
export default class Nomenclature extends React.Component {
	@observable isLoading = true;
	@observable addableNomenclature = null;
	@observable isShowPopup = false;
	@observable newNomenclatureName = '';
	@observable error = '';
	constructor(props) {
		super(props);
		this.init();
	}
	init = () => {
		const { productStore } = this.props;
		if (!!productStore.productId && !!store.route?.params?.isAddNomenclature) this.addNewNomenclature();
	};

	addNewNomenclature = () => {
		const { productStore } = this.props;

		const newNomenclature = new store.model.Nomenclature();
		newNomenclature.productId = productStore.productId;
		newNomenclature.name = productStore.product.name;
		productStore.nomenclatures.splice(0, 0, newNomenclature);
	};

	addedNomenclature = async () => {
		const { productStore } = this.props;

		this.addableNomenclature.productId = productStore.productId;
		await this.addableNomenclature.save();

		productStore.nomenclatures.splice(0, 0, this.addableNomenclature);
		this.addableNomenclature = null;
	};

	onChangeNomenclature = (value) => {
		this.addableNomenclature = value;
	};

	renderItemsSelect = (record) => {
		return (
			<div className='wrapper-nomenclature-value'>
				{record.name}
				{!!record.article && (
					<span>
						{t('article')}: {record.article}
					</span>
				)}
			</div>
		);
	};

	render() {
		const { productStore } = this.props;
		const quantity = productStore?.nomenclatures?.length;
		if (productStore.isLoading) return '...';

		return (
			<Section
				title={
					<>
						{t('product.nomenclatures')} - {quantity}
					</>
				}
			>
				<div className='nomenclatures-actions'>
					<div className='nomenclatures-actions-wrapper'>
						<Button onClick={this.addNewNomenclature} endIcon={renderFaIcons.circlePlus}>
							{t('product.createNomenclature')}
						</Button>
						<span>или</span>
						<RecordSelect
							model={store.model.Nomenclature}
							property='name'
							placeholder={'Выбрать номенклатуру'}
							onChange={this.onChangeNomenclature}
							filter={{ where: { productId: null } }}
							value={this.addableNomenclature}
							width={200}
							computed={this.renderItemsSelect}
							searchFields={['name', 'article']}
						/>
						{this.addableNomenclature && <ApproveIconButton onClick={this.addedNomenclature} />}
					</div>

					<FeatureSelector featureType='variant' text={t('feature.title')} />
				</div>
				<VariantsList />
			</Section>
		);
	}
}
