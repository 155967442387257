import React from 'react';
import { observer, Provider } from 'mobx-react';
import { SingleDialogStore } from 'client/modules/messages/dialogs/single/singleDialogStore';
import { Loader } from '@smartplatform/ui';
import './style.scss';
import MainSection from 'client/modules/messages/dialogs/single/MainSection';
import Sidebar from 'client/modules/messages/dialogs/single/Sidebar';
import { DialogLog } from 'client/modules/messages/dialogs/single/history';
import HistoryTabs from 'components/history-tab/HistoryTabs';
import t from 'i18n';
import { Message } from 'components/post-message/Message';
import store from 'client/store';
import { triggerErrorNotification } from 'components';

const FUNCTION_NAMES = {
	VK: 'VKSendMessage',
	TLG: 'telegramSendMessage',
	EMAIL: 'emailSendMessage',
};

@observer
export class SingleDialog extends React.Component {
	constructor(props) {
		super(props);
		const dialogId = props.match.params?.id;
		this.store = new SingleDialogStore(dialogId);
	}

	componentDidMount = () => {
		store.socket.on(`Dialog:${this.store.dialogId}`, () => this.store.historyInstance.reload());
	};

	componentWillUnmount = () => {
		store.socket.off(`Dialog:${this.store.dialogId}`);
	};

	renderDialogLog = (record, index, onShowPopup) => {
		if (!record.updated) return null;
		const { dialog } = this.store;
		const member = this?.members?.find((member) => member.userId === record.ownerId) || null;
		const memberRole = member?.role?.name;
		const isOwner = record.ownerId === dialog.ownerId;
		const roles = [isOwner && t('author'), memberRole].filter(Boolean);
		return (
			<DialogLog
				roles={roles}
				key={record.id}
				log={record}
				index={index}
				project={dialog}
				onShowPopup={onShowPopup({ baseModelRelationName: 'dialog', log: { index: index, record: record } })}
			/>
		);
	};

	renderMessage = (record) => {
		const onIntersection = () => {
			if (record.hasOwnProperty('hasRead') && !record.hasRead) {
				record.hasRead = true;
				record.save();
			}
		};
		return <Message record={record} onIntersection={onIntersection} />;
	};

	onSubmitComment = async (message) => {
		try {
			const { dialog } = this.store;
			if (FUNCTION_NAMES?.[dialog?.source?.code])
				await store.model.DialogSource[FUNCTION_NAMES?.[dialog?.source?.code]]({
					externalId: dialog.externalId,
					messageId: message.id,
					email: dialog.email,
				});
		} catch (error) {
			console.error(error);
			if (error?.message) triggerErrorNotification(t('postMessage.errorSend'));
		}
	};

	render() {
		const { isLoading, dialog, getHistoryInstance } = this.store;

		if (isLoading) return <Loader size={20} />;
		if (!dialog) return null;

		const disabledSidebar = dialog.isSpam;

		let historyProps = {};
		if (dialog.id) {
			historyProps = {
				record: dialog,
				getInstance: getHistoryInstance,
				tabs: [
					{
						title: t('history.dialog.messages'),
						computed: this.renderMessage,
						type: 'messages',
						onSubmit: this.onSubmitComment,
					},
					{
						title: t('dialog.log'),
						filter: {
							include: ['status', 'owner'],
						},
						computed: this.renderDialogLog,
						type: 'logs',
					},
				],
			};
		}

		return (
			<Provider store={this.store}>
				<div className='wrapper-single-application'>
					<div className='left-application'>
						<MainSection />
						{dialog.id && <HistoryTabs {...historyProps} />}
					</div>
					{!disabledSidebar && (
						<div className='right-application'>
							<Sidebar />
						</div>
					)}
				</div>
			</Provider>
		);
	}
}
