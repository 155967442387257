import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import './style.scss';
import { DateTimePicker, LabelField, DateRangePicker } from 'components';

@observer
export default class DatePickerTest extends React.Component {
	@observable dateTimaValue = null;

	@observable startDate;
	@observable endDate;

	onChange = (prop) => (value) => (this[prop] = value);
	onStartChange = (value) => (this.startDate = value);
	onEndChange = (value) => (this.endDate = value);

	@observable dateRange = [null, null];
	onChangeDateRange = (range) => (this.dateRange = range);

	render() {
		const { onChange, dateTimaValue, onStartChange, onEndChange, startDate, endDate, onChangeDateRange, dateRange } = this;

		return (
			<div className='date-picker-test'>
				<LabelField label='DateTimePicker'>
					<DateTimePicker value={dateTimaValue} onChange={onChange('dateTimaValue')} />
				</LabelField>
				<LabelField label='DateRangePicker'>
					<DateRangePicker
						startDate={startDate}
						endDate={endDate}
						onStartChange={onStartChange}
						onEndChange={onEndChange}
						onChangeDateRange={onChangeDateRange}
						dateRange={dateRange}
					/>
				</LabelField>
			</div>
		);
	}
}

