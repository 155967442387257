import React from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Kanban, Select } from '@smartplatform/ui';
import { ORDER_KANBAN_INCLUDE, KANBAN_INITIAL_LIMIT, DEFAULT_PRIORITY, ORDER_KANBAN_SORTING_VARIANTS } from '../constants';
import { KanbanSkeleton } from 'components';
import OrderCard from './OrderCard';
import store from 'client/store';
import { ORDERS_PATH } from 'client/modules/sales/constants';
import t from 'i18n';

import './ordersKanban.scss';

@inject('store')
@observer
export class OrdersKanban extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.isLoading = true;
		this.store.fetchKanbanRecords();
	}

	renderOrder = (order, list, options) => <OrderCard order={order} list={list} {...options} store={this.store} />;

	onChangeKanban = async ({ item, text, prev, next, list, index }) => {
		this.saving = true;
		const order = new store.model.Order(item);
		order.listId = list.id;

		if (!item) {
			order.description = text;
		}

		if (!prev) {
			order.priority = next ? next.priority * 2 : DEFAULT_PRIORITY;
		} else if (!next) {
			order.priority = prev ? prev.priority / 2 : DEFAULT_PRIORITY;
		} else {
			order.priority = (prev.priority + next.priority) / 2;
		}

		try {
			await order.save();
			const updatedRecord = await store.model.Order.findById(order.id, { fields: ['id', 'closed', 'updatedAt'] });
			order.updatedAt = updatedRecord.updatedAt;
			order.closed = updatedRecord.closed;
			this.store.saving = false;

			if (!item) store.route.push({ path: `${ORDERS_PATH}/${order.id}` });
			this.saving = false;
		} catch (e) {
			this.saving = false;
			throw e;
		}

		// TODO: возвращать данные из item, а не project?

		return {
			id: order.id,
			listId: order.listId,
			path: `${ORDERS_PATH}/${order.id}`,
			data: order,
		};
	};

	gotoItem = (path) => store.route.push({ path });

	fakeDelay = (delay = 1000) => new Promise((resolve) => setTimeout(resolve, delay));

	getInstance = (instance) => (this.store.kanban = instance);

	loadMore = async (list) => {
		const ordersWhere = this.store.filters.and;
		console.log('loadMore', list.items.length);

		const moreOrders = await store.model.Order.find({
			where: {
				and: [...ordersWhere, { listId: list.id }],
			},
			order: 'priority desc',
			limit: KANBAN_INITIAL_LIMIT,
			skip: list.items.length,
			include: ORDER_KANBAN_INCLUDE,
		});

		return moreOrders.map((order) => ({
			id: order.id,
			listId: order.listId,
			path: `${ORDERS_PATH}/${order.id}`,
			data: order,
		}));
	};

	render() {
		const { isLoading, kanbanLists, order, onChange, hiddenLists } = this.store;

		if (isLoading)
			return (
				<div className='orders-kanban'>
					<KanbanSkeleton />
				</div>
			);

		if (!kanbanLists.length)
			return (
				<>
					Не заданы <Link to={`/orderlists`}>состояния заказов</Link>
				</>
			);

		const kanbanProject = {
			name: t('order.plural'),
			lists: kanbanLists.map((list) => {
				return {
					id: list.id,
					name: list.name,
					color: list.color,
					totalCount: list.totalCount,
					data: list,
					items: list.orders().map((order) => {
						return {
							id: order.id,
							listId: order.listId,
							path: `${ORDERS_PATH}/${order.id}`,
							data: order,
						};
					}),
				};
			}),
		};

		return (
			<>
				<div className='pager-and-sort-kanban'>
					<Select items={ORDER_KANBAN_SORTING_VARIANTS} value={order} onChange={onChange('order')} isRequired />
				</div>
				<div className='orders-kanban'>
					<div className='orders-kanban-container'>
						<Kanban
							project={kanbanProject}
							renderItem={this.renderOrder}
							itemClassName='order-kanban-card'
							onChange={this.onChangeKanban}
							getInstance={this.getInstance}
							loadMore={this.loadMore}
							onError={this.onError}
							gotoItem={this.gotoItem}
							itemsLimit={20}
							hiddenLists={hiddenLists}
						/>
					</div>
				</div>
			</>
		);
	}
}

