import React from 'react';
import { createRoot } from 'react-dom/client';
import store from './store';
import App from './App';

const render = (Component) => {
	const root = createRoot(document.getElementById('root'));
	root.render(<Component />);
};

const init = async () => {
	await store.init();
	render(App);
};

init();

