import React from 'react';
import { AutoAnimate, Loader } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import t from 'i18n';
import { PostComment } from 'components';
import HistoryDeltaPopup from 'components/history-delta-popup/HistoryDeltaPopup';
import { PostMessage } from 'components/post-message';

@observer
export default class HistoryTabContent extends React.Component {
	refMessages = null;
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onMount = (el) => {
		if (el) {
			el.addEventListener('scroll', this.onScroll);
		}
	};

	messagesOnMount = (el) => {
		const { items } = this.store;
		if (el) this.refMessages = el;
		if (items.length) this.refMessages.scrollTop = this.refMessages.scrollHeight;
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.scrollBottom();
	}

	onScroll = async (e) => {
		if (this.loadingMore || this.store.items.length >= this.store.items.totalRecords) return;
		const bottomScroll = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight;
		if (bottomScroll < 50) {
			this.loadingMore = true;
			this.store.page = this.store.page + 1;
			await this.store.fetchRecords();
			this.loadingMore = false;
		}
	};

	onSubmit = (record) => {
		if (this.store.activeTab?.onSubmit) this.store.activeTab?.onSubmit(record);
		this.store.reload();
	};

	scrollBottom = () => {
		const { items } = this.store;
		if (items.length && !!this.refMessages) this.refMessages.scrollTop = this.refMessages.scrollHeight;
	};

	render() {
		const { items, activeTab, record, onShowPopup, popup, reload, isTabInit, isInit } = this.store;
		if (!isInit) return <Loader size={20} />;
		const contentMessages = items.length
			? items.map((record, index) => activeTab.computed(record, index, onShowPopup))
			: t('history.notMessages');

		const contentCommentsOrLogs = items.length
			? items.map((record, index) => activeTab.computed(record, index, onShowPopup))
			: t('history.notFound');

		const contentToRender =
			activeTab.type !== 'messages' ? (
				isTabInit ? (
					contentCommentsOrLogs
				) : (
					<Loader size={20} />
				)
			) : (
				<AutoAnimate className='wrapper-messages' ref={this.messagesOnMount}>
					{isTabInit ? contentMessages : <Loader size={20} />}
				</AutoAnimate>
			);

		return (
			<div className='wrapper-history-content' ref={this.onMount}>
				{activeTab.type === 'comments' && <PostComment record={record} className='post-comment' onSubmit={this.onSubmit} />}
				{contentToRender}
				{activeTab.type === 'messages' && (
					<PostMessage record={record} className='post-message' onSubmit={this.onSubmit} onToggleEdit={this.scrollBottom} />
				)}
				{popup && <HistoryDeltaPopup {...popup} />}
			</div>
		);
	}
}
