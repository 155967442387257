import React from 'react';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { fioShort, renderFaIcons } from 'client/tools';
import PasswordWithValidate from 'components/auth/components/field-password/PasswordWithValidate';
import { ErrorMessage, FieldPassword } from 'components/auth/components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form, triggerErrorNotification } from 'components';
import { Avatar } from '@smartplatform/ui';

@observer
export class RewritePassword extends React.Component {
	@observable isLoading = true;
	@observable password = '';
	@observable password2 = '';
	@observable passwordOld = '';
	@observable isPasswordVisible = false;
	@observable validPasswordComplexity = true;
	@observable error = null;
	@observable user = null;
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		if (store.model.user) {
			this.user = await store.model.User.findById(store.model.user.id);
		}

		const _countUniqueCharts = parseInt(store.mergedConfig?.authentication?.password?.countUniqueCharts);
		this.enableUniqueCharts = !!_countUniqueCharts && _countUniqueCharts !== 0;
		this.isLoading = false;
	};

	beforeSave = async () => {
		try {
			if (this.password.length > 0 || this.password2.length > 0) {
				if (this.password !== this.password2) throw new Error(t('password.noMatch'));
			}
			this.user.password = this.password;
			this.user.passwordOld = this.enableUniqueCharts ? this.passwordOld : null;
		} catch (error) {
			const message = error.message;
			triggerErrorNotification(message);
			return false;
		}
	};

	onChange = (prop) => (e) => {
		this[prop] = e.target.value;
	};

	onSave = async () => {
		this.user.password = undefined;
		store.route.push({ path: '/management/tasks' });
	};

	render() {
		if (!this.user) return null;
		if (this.isLoading) return <Loader />;

		let disabledForm = !this.validPasswordComplexity || !this.password || !this.password2;
		if (this.enableUniqueCharts) disabledForm = disabledForm || !this.passwordOld;

		return (
			<div className='wrapper-rewrite-password'>
				<div className='user-section'>
					<Avatar user={store.model.user} size={26} />
					<span className='fio'>{fioShort(store.model.user)}</span>
				</div>
				<div className='head'>
					<strong>{t('rewritePassword')}</strong>
					<div title={t('rewritePasswordHint')}>{renderFaIcons.info}</div>
				</div>
				<Form
					record={this.user}
					className='form-rewrite-password'
					noDelete
					beforeSave={this.beforeSave}
					onSave={this.onSave}
					checkRequiredFields={false}
					disableSave={disabledForm}
					onCancel={() => store.model.logout()}
				>
					<div className='form-field'>
						<PasswordWithValidate
							label={t('password.new')}
							value={this.password}
							onChange={this.onChange('password')}
							onCheckInvalid={(items) => (this.validPasswordComplexity = !items.length)}
							options={store.mergedConfig?.authentication?.password}
						/>
					</div>
					<FieldPassword
						label={t('password.repeat')}
						value={this.password2}
						onChange={this.onChange('password2')}
						disabled={!this.password && !this.password2}
					/>
					{this.enableUniqueCharts && (
						<FieldPassword label={t('password.old')} value={this.passwordOld} onChange={this.onChange('passwordOld')} />
					)}
					{this.error && <ErrorMessage field={this.error} />}
				</Form>
			</div>
		);
	}
}
