import { observable } from 'mobx';
import { formatDate } from 'client/tools';
import { DAYS_NAME } from 'components/calendar-records/constants';

export default class CalendarRecordsStore {
	@observable mode = 'week';
	@observable currentDate = new Date(new Date().setHours(0, 0, 0, 0));
	@observable daysCalendar = [];
	@observable valueSwitcher = '';
	@observable startDate = null;
	@observable endDate = null;
	@observable draggedItem = null;
	@observable records = [];
	@observable recordsWithoutDate = { records: [], totalCount: 0 };
	props = null;

	constructor(props) {
		this.props = props;
		this.init();
	}

	fetchRecordsByDate = async (filter) => {
		const _and = [];
		_and.push({ [this.props.dateProperty]: { between: [this.startDate, this.endDate] } });
		const _where = structuredClone(filter.where);
		_where.and = filter.where?.and?.length ? [..._and, ...filter.where.and] : _and;
		const _filter = { ...filter, where: _where };
		this.records = await this.props.model.find(_filter);
	};

	fetchRecordsWithoutDate = async (filter, page = 1, hideClosed) => {
		const _and = [];
		_and.push({ [this.props.dateProperty]: { eq: null } });

		if (hideClosed && this.props.closedFilter) {
			_and.push({ closed: false });
		}
		const _where = structuredClone(filter.where);
		_where.and = filter.where?.and?.length ? [..._and, ...filter.where.and] : _and;

		const _filter = { ...filter, where: _where, limit: 20, skip: 20 * (page - 1) };

		if (page === 1) {
			this.recordsWithoutDate.records = [];
			const count = await this.props.model.count(_filter.where);
			this.recordsWithoutDate.totalRecords = count;
		}

		const records = await this.props.model.find(_filter);
		this.recordsWithoutDate.records = [...this.recordsWithoutDate.records, ...records];
	};

	init = () => {
		if (this.props?.initialState) {
			for (const prop in this.props?.initialState) {
				if (this.props?.initialState[prop]) this[prop] = this.props?.initialState[prop];
			}
		}
		this.getDaysCalendar();
	};

	getDaysCalendar = () => {
		if (this.mode === 'week') {
			this.getAllDaysInWeek(this.currentDate);
			this.valueSwitcher = `${formatDate(this.startDate, 'dd.MM')} - ${formatDate(this.endDate, 'dd.MM')}`;
		} else {
			this.getAllDaysInMonth(this.currentDate.getFullYear(), this.currentDate.getMonth());
			this.valueSwitcher = this.currentDate.toLocaleString('ru', { month: 'long', year: 'numeric' });
		}
	};

	getAllDaysInWeek = (currentDate) => {
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);
		this.startDate = new Date(currentDate.setDate(diff));
		this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() + 6);
		this.daysCalendar = DAYS_NAME.map((item, index) => {
			const date = formatDate(
				new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() + index),
				'dd'
			);
			const fullDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() + index);
			return {
				name: item,
				numberDay: date,
				fullDate: fullDate,
				active: fullDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? true : false,
				totalRecords: 0,
			};
		});
	};

	getAllDaysInMonth = (year, month) => {
		const firstDay = new Date(year, month, 1);
		const lastDay = new Date(year, month + 1, 0);
		const firstDayOfWeek = firstDay.getDay();
		const lastDate = lastDay.getDate();
		const days = [];

		// Days from previous month
		for (let i = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1; i >= 0; i--) {
			const prevDay = new Date(year, month, -i);
			if (prevDay.getDay() !== 0) {
				days.push(prevDay);
			}
		}

		// Days from current month
		for (let i = 1; i <= lastDate; i++) {
			const currentDay = new Date(year, month, i);
			days.push(currentDay);
		}

		// Days from next month
		const lastDayOfWeek = lastDay.getDay();
		if (lastDayOfWeek !== 0) {
			for (let i = 1; i <= 7 - lastDayOfWeek; i++) {
				const nextDay = new Date(year, month + 1, i);
				days.push(nextDay);
			}
		}

		if (firstDayOfWeek === 1) {
			this.startDate = firstDay;
		} else {
			this.startDate = new Date(year, month, -(firstDayOfWeek - 1));
		}
		if (lastDayOfWeek === 0) {
			this.endDate = lastDay;
		} else {
			this.endDate = new Date(year, month + 1, 7 - lastDayOfWeek);
		}

		this.daysCalendar = this.groupDaysByWeekDay(days);
	};

	groupDaysByWeekDay = (days) => {
		const groupedDays = {};
		days.forEach((day) => {
			const dayOfWeek = day.getDay() === 0 ? 7 : day.getDay();
			if (!groupedDays[dayOfWeek]) {
				groupedDays[dayOfWeek] = {
					name: DAYS_NAME[dayOfWeek - 1],
					days: [],
				};
			}
			groupedDays[dayOfWeek].days.push({
				numberDay: formatDate(day, 'dd'),
				fullDate: day,
				active: day.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? true : false,
				totalRecords: 0,
			});
		});
		return groupedDays;
	};

	onChange = (prop, value) => {
		if (!!prop && !!value) {
			this[prop] = value;
			if (prop === 'mode' && this.props.onLocalStorageSave) this.props.onLocalStorageSave('mode', this.mode);
		}
	};

	onPrev = (onDataChange) => {
		this.currentDate =
			this.mode === 'week'
				? new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 7)
				: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
		this.getDaysCalendar();
		if (onDataChange) onDataChange();
		if (this.props.onLocalStorageSave) this.props.onLocalStorageSave('currentDate', this.currentDate);
	};

	onNext = (onDataChange) => {
		this.currentDate =
			this.mode === 'week'
				? new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + 7)
				: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
		this.getDaysCalendar();
		if (onDataChange) onDataChange();
		if (this.props.onLocalStorageSave) this.props.onLocalStorageSave('currentDate', this.currentDate);
	};

	handleDragStart = (e, item) => {
		this.draggedItem = item;
	};

	handleDrop = async (e) => {
		e.preventDefault();
		if (!this.props.beforeSave) {
			this.draggedItem.save();
		} else {
			this.props.beforeSave(this.draggedItem).save();
		}
		this.draggedItem = null;
	};

	handleDragOver = (e, date) => {
		e.preventDefault();
		if (!date) {
			this.draggedItem[this.props.dateProperty] = null;
			return;
		}
		this.draggedItem[this.props.dateProperty] = date;
	};
}
