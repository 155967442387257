import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, CancelButton, Editor, LabelField, Section } from 'components';
import t from 'i18n';
import store from 'client/store';
import { Checkbox, Input, RecordSelect, Row } from '@smartplatform/ui';
import { renderPlaceholder, renderStatus } from 'client/tools';

@inject('store')
@observer
export default class MainSection extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onSaveDescription = async () => {
		await this.store.save();
		this.store.editMode = false;
	};

	render() {
		const { dialog, onChangeDialog, editMode, toggleEditMode, onChangeDialogAndSave, save, onRelationChange } = this.store;

		const headerSection = (
			<div className='wrapper-head-section'>
				<p className='title-section'>{dialog.name || dialog.email || dialog.phone || dialog.username}</p>
			</div>
		);

		let selectedStatus = dialog.status ? renderStatus(dialog.status) : renderPlaceholder(t('select'));

		return (
			<Section title={headerSection}>
				<div className='top-main-section'>
					<Row>
						{['username', 'name', 'email', 'phone'].map((field, index) => (
							<LabelField label={t(`dialog.${field}`)} key={index}>
								<Input value={dialog[field]} onChange={onChangeDialog(field)} onBlur={save} />
							</LabelField>
						))}
					</Row>
					<div className='row-field'>
						<LabelField label={t('dialog.status')}>
							<RecordSelect
								value={dialog.status}
								model={store.model.DialogStatus}
								showValue={selectedStatus}
								onChange={onRelationChange('status')}
								computed={renderStatus}
								width={220}
							/>
						</LabelField>
					</div>
					<div className='row-field'>
						{!dialog.isClosed && (
							<div className='wrapper-field'>
								<Checkbox checked={dialog.isSpam} label={t('dialog.isSpam')} onChange={onChangeDialogAndSave('isSpam')} />
							</div>
						)}
						{!dialog.isSpam && (
							<div className='wrapper-field'>
								<Checkbox
									checked={dialog.isClosed}
									label={t('dialog.isClosed')}
									onChange={onChangeDialogAndSave('isClosed')}
								/>
							</div>
						)}
					</div>
				</div>
				<div className='delimeter' />
				<div className='bottom-main-section'>
					<div className='wrapper-field'>
						<p>{t('description')}:</p>
						{editMode && (
							<Editor
								key={dialog.id}
								value={dialog.description}
								onChange={onChangeDialog('description')}
								mediaModel={store.model.Attachment}
								autoResize={true}
								minHeight={150}
								maxHeight={500}
							/>
						)}
						{!editMode && dialog.description && (
							<div className='description-content' dangerouslySetInnerHTML={{ __html: dialog.description }} />
						)}
						<div className='editor-control'>
							<Button text={editMode ? t('save') : t('edit')} onClick={editMode ? this.onSaveDescription : toggleEditMode} />
							{editMode && <CancelButton onClick={toggleEditMode} />}
						</div>
					</div>
				</div>
			</Section>
		);
	}
}
