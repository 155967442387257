import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Link } from 'react-router-dom';
import InputTest from './input';
import ButtonTest from './button';
import PopoverTest from './popover';
import DropDownTest from './dropdown/DropDownTest';
import DatePickerTest from './datepicker/DatePickerTest';
import FormTest from './form/FormTest';
import TabsTest from './tabs/TabsTest';
import GroupSelectTest from './groupselect/GroupSelectTest';
import RecordSelectTest from './recordselect/RecordSelectTest';
import TableTest from './table/TableTest';
import './style.scss';
import Notifications from './notifications/Notifications';

@observer
export default class Test extends React.Component {
	render() {
		return (
			<div className='test'>
				<div className='test-menu'>
					<Link to='/test/datepicker'>Date Picker</Link>
					<Link to='/test/notifications'>Notifications</Link>
					<Link to='/test'>Button</Link>
					<Link to='/test/input'>Input</Link>
					<Link to='/test/popover'>Popover</Link>
					<Link to='/test/dropdown'>DropDown</Link>
					<Link to='/test/tabs'>Tabs</Link>
					<Link to='/test/form'>Form</Link>
					<Link to='/test/groupselect'>GroupSelect</Link>
					<Link to='/test/recordselect'>RecordSelect</Link>
					<Link to='/test/table'>Table</Link>
				</div>
				<div className='test-content'>
					<Switch>
						<Route path='/test' exact>
							<ButtonTest />
						</Route>
						<Route path='/test/datepicker'>
							<DatePickerTest />
						</Route>
						<Route path='/test/input'>
							<InputTest />
						</Route>
						<Route path='/test/popover'>
							<PopoverTest />
						</Route>
						<Route path='/test/dropdown'>
							<DropDownTest />
						</Route>
						<Route path='/test/tabs'>
							<TabsTest />
						</Route>
						<Route path='/test/form'>
							<FormTest />
						</Route>
						<Route path='/test/groupselect'>
							<GroupSelectTest />
						</Route>
						<Route path='/test/recordselect'>
							<RecordSelectTest />
						</Route>
						<Route path='/test/table'>
							<TableTest />
						</Route>
						<Route path='/test/notifications'>
							<Notifications />
						</Route>
					</Switch>
				</div>
			</div>
		);
	}
}

